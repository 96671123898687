const initialState = {
    profileData: [],
    earningData: [],
    languageData: [],
    footerData: [],
    countryData: []
};

const rootReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case 'GET_PROFILE_DATA':
            return {
                ...state,
                profileData: action.payload,
            };
        case 'GET_EARNING_DATA':
            return {
                ...state,
                earningData: action.payload,
            };
        case 'GET_LANGUAGE_DATA':
            return {
                ...state,
                languageData: action.payload,
            };
        case 'GET_FOOTER_DATA':
            return {
                ...state,
                footerData: action.payload,
            };
        case 'GET_COUNTRY_DATA':
            return {
                ...state,
                countryData: action.payload,
            };
        default:
            return state;
    }
};

export default rootReducer;