import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    useLocation,
    useNavigate,
    useParams
} from "react-router-dom";

function withRouter(Component: any) {
    function ComponentWithRouterProp(props: any) {
        const currentLanguage = localStorage.getItem("I18N_LANGUAGE") as any;

        const { t } = useTranslation();
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();

        useEffect(() => {
            const { search } = location;
            const urlParams = new URLSearchParams(search);
            const langInUrl = urlParams.get("lang");
            if (currentLanguage === "en" && langInUrl) {
                urlParams.delete("lang");
                navigate({ ...location, search: urlParams.toString() });
            } else if (currentLanguage !== "en" && (!langInUrl || langInUrl !== currentLanguage)) {
                urlParams.set("lang", currentLanguage);
            }
        }, [location, currentLanguage, navigate]);
        return (

            <Component
                {...props}
                t={t}
                router={{ location, navigate, params }}
            />
        );
    }

    return ComponentWithRouterProp;
}

export default withRouter;