import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from "react-helmet";
import axios from '../network/axios';
import "../assets/scss/app-home.scss";
import icLeftArrow from '../assets/images/home/arrow-left.png';
import icRightArrow from '../assets/images/home/arrow-right.png';
import { ROUTE_LOGIN, ROUTE_REGISTER } from '../network/urls';
import { API_GET_FAQ_HELP, API_HOME } from '../network/apis';
import { showError, showErrors } from '../common/error-handler';
import { Link } from 'react-router-dom';
import LanguageDropdownGuest from '../layouts/common/LanguageDropdownGuest';
import { useTranslation } from 'react-i18next';
import withRouter from '../common/withRouter';
import {  IMAGE_CDN_PUBLIC } from '../common/config';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/a11y';
import UndefinedImage from './../common/UndefinedImage';
interface TestimonialProps {
    data: any;
    whatPeopleSayTestimonial: any[];
}
// top Header section start 
const TopHeader = () => {
    const { t } = useTranslation();
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    return (<div className='top-header'>
        <nav className="navbar navbar-expand-lg">
            <div className="container-fluid">
                <a className="navbar-brand" href={process.env.REACT_APP_BASE_URL}>
                    <div className='logo'></div>
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" aria-label="Toggle navigation" onClick={() => setShowMobileMenu(!showMobileMenu)}>
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="navbar-collapse pull-right collapse" style={{ display: (showMobileMenu ? "block" : "none") }}>
                    <ul className="nav navbar-nav">
                        <li className="nav-item">
                            <a className="nav-link text-black active" aria-current="page" href={process.env.REACT_APP_LIVE_SITE_BASE_URL + "photos"} target='_blank'>{t("Marble")}</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link text-black" href={process.env.REACT_APP_LIVE_SITE_BASE_URL + "photos"} target='_blank'>{t("Stock Photos")}</a>
                        </li>
                    </ul>

                    <ul className="navbar-nav ms-auto" id="nav-right">
                        <li className='nav-btn-item'>
                            <a className='nav-btn fs18 btn-hover' target='_blank' href={process.env.REACT_APP_LIVE_SITE_BASE_URL + "plan"}>{t("Buy")}</a>
                        </li>
                        <li className='nav-btn-item'>
                            <LanguageDropdownGuest class="header-language" />
                        </li>
                        <li className="nav-item align-items-center d-lg-flex">
                            <Link className='nav-link text-black' to={ROUTE_LOGIN}>{t("Login")}</Link>
                        </li>
                        <li className="nav-item no-mobile align-items-center d-lg-flex">
                            <b className='nav-link text-black'>|</b>
                        </li>
                        <li className="nav-item align-items-center d-lg-flex">
                            <Link className='nav-link text-black' to={ROUTE_REGISTER}>{t("Signup")}</Link>
                        </li>
                    </ul>

                </div>
            </div>
        </nav>
    </div>)
}
// side bar section start 
const SlideShow = (props: any) => {
    const { t } = useTranslation();
    const SLIDE_DATA = [
    ] as any;
    props.data && props.data.map((slide: any, i: any) => {
        SLIDE_DATA.push({ head: <>{slide.title}</>, subHead: <>{slide.subtitle}</>, btnText: t("Become a contributor"), btnLink: slide.link, slideImg: slide.image });
    })
    const SLIDE_CHANGE_MILISECONDS = 3000;
    const FIRST_INDEX = 0;
    const LAST_INDEX = SLIDE_DATA.length - 1;
    const [slideIndex, setSlideIndex] = useState(FIRST_INDEX);

    const nextSlide = (moveForward: boolean) => {
        if (timeoutId != undefined) {
            clearTimeout(timeoutId);
        }
        if (moveForward) {
            if (slideIndex < LAST_INDEX) {
                setSlideIndex(slideIndex + 1);
            } else {
                setSlideIndex(FIRST_INDEX);
            }
        }
        else {
            if (slideIndex > FIRST_INDEX) {
                setSlideIndex(slideIndex - 1);
            } else {
                setSlideIndex(LAST_INDEX);
            }
        }
    }

    var timeoutId: any = undefined;

    useEffect(() => {
        timeoutId = setTimeout(function () {
            nextSlide(true);
        }, SLIDE_CHANGE_MILISECONDS);
    }, [slideIndex]);

    return (<section className='slideshow' style={{ background: "url(" + SLIDE_DATA[slideIndex]["slideImg"] + ") no-repeat" }}>
        <div className='slide-content'>
            <button type="button" className="slide-btn left" onClick={(e: any) => {
                nextSlide(false);
            }}><img src={icLeftArrow} alt="slide-left-arrow" /></button>
            <div className='main-content'>
                <h1 className='head'>{SLIDE_DATA[slideIndex]["head"]}</h1>
                <h3 className='subhead'>{SLIDE_DATA[slideIndex]["subHead"]}</h3>
                <Link type="button" className="btn btn-home-page-primary" to={SLIDE_DATA[slideIndex]["btnLink"]}>{SLIDE_DATA[slideIndex]["btnText"]}</Link>
            </div>
            <button type="button" className="slide-btn right" onClick={(e: any) => {
                nextSlide(true);
            }}><img src={icRightArrow} alt="slide-right-arrow" /></button>
        </div>
    </section>)
}
// Why Cliqstock section start
const Why = ({ data }: any) => {
    const { t } = useTranslation();
    return (<section className='why'>
        <div className='mcontainer w-100'>
            <div className='sec-header'>
                <h1 className="title">{data.title}</h1>
                <p className='description'>{data.subtitle} </p>
            </div>
            <div className='img-thumbs'>
                {data.sub_section && data.sub_section.map((th: any, i: any) => {
                    return (<Link className='thumb text-black' to={th.link} key={i}>
                        <img src={th.image} alt={th.title} />
                        <h3 className='title'>{th.title}</h3>
                        <p>{th.sub_title}</p>
                    </Link>)
                })}
            </div>
            <Link type="button" className="btn btn-home-page-secondary" to={data.link} target='_blank'>{t("Learn More")}</Link>
        </div>
    </section>)
}

// Who Become a contributor section start
const BecomeContributor = ({ data }: any) => {
    const { t } = useTranslation();
    return (<section className='become-contributor'>
        <div className='sec-header'>
            <h1 className="title">{data.title}</h1>
            <p className='description'>{data.subtitle} </p>
        </div>
        <div className='thumbs-profession flex-md-wrap w-100 justify-content-around'>
            {data.sub_section && data.sub_section.map((th: any, i: any) => {
                return (<a className='thumb th-black' href={th.link} key={i}>
                    <div className='imgContainer'>
                        <img src={th.image} alt={th.image} />
                    </div>
                    <label className='title'>{th.title}</label>
                    <p>{th.subtitle}</p>
                </a>)
            })}
        </div>
        <Link className="btn btn-home-page-primary" to={data.link}>{t("Signup Now")}</Link>
    </section >)
}

// comparison section start 
const Comparison = ({ data }: any) => {
    const { t } = useTranslation();
    return (<section className='comparison'>
        <div className='mcontainer'>
            <div className='sec-header'>
                <h1 className="title">{data.title}</h1>
                <p className='description'>{data.subtitle}</p>
            </div>

            <div className='thumbs-comparison'>
                {data.sub_section && data.sub_section.map((th: any, i: any) => {
                    return (<a className='th-horizontal text-black' href={th.link} key={i}>
                        <div className='imgContainer'>
                            <img src={th.image} alt={th.image} />
                        </div>
                        <label>{th.title}</label>
                        <p>{th.sub_title}</p>
                    </a>)
                })}
            </div>
            <Link type="button" className="btn btn-home-page-secondary" to={data.link}>{t("Learn More")}</Link>
        </div>
    </section >)
}
// Reason to Work with Us section start 
const Reasons = ({ data }: any) => {
    const { t } = useTranslation();
    return (<section className='reasons'>
        <div className='mcontainer'>
            <div className='sec-header'>
                <h1 className="title">{data.title}</h1>
            </div>

            <div className='thumbs-reasons'>
                {data.sub_section && data.sub_section.map((th: any, i: any) => {
                    return (<a className='thumb text-black' href={th.link} key={i}>
                        <div className='imgContainer'>
                            <img src={th.image} alt='Contract' />
                        </div>
                        <h3 className='title'>{th.title}</h3>
                        <p className='description'>{th.sub_title}</p>
                    </a>)
                })}
            </div>
            <Link type="button" className="btn btn-home-page-primary" to={data.link}>{t("Learn More")}</Link>
        </div>
    </section >)
}
// How it works section start 
const HowWorks = (props: any) => {
    const { t } = useTranslation();
    const { data } = props;
    return (<section className='how-works'>
        <div className='mcontainer'>
            <div className='sec-header'>
                <h1 className="title">{data.title}</h1>
                <p className='description'>{data.subtitle}</p>
            </div>

            <div className='thumbs-horizontal'>
                {data.sub_section && data.sub_section.map((th: any, i: any) => {
                    return (<a className='thumb text-black' href={th.link} key={i}>
                        <div className='imgContainer'>
                            <img src={th.image} alt={th.image} />
                        </div>
                        <label>{th.title}</label>
                    </a>)
                })}
            </div>
            <div className='sec-footer'>
                <h5>{t(props.fileSupport[0]["title"])}</h5>
                <div className='file-types'>
                    {props.fileSupport && props.fileSupport.map((file: any, i: number) => {
                        return (<img src={file.image} alt="PSB" width={650} key={i}/>)
                    })}
                </div>
                <h5>{t(props.fileSupport[0]["subtitle"])}</h5>
            </div>
            <Link className="btn btn-home-page-secondary" to={ROUTE_REGISTER}>{t("Signup Now")}</Link>
        </div>
    </section >)
}

// What we have section start 
const WhatWeHave = ({ data, whatWeHaveMarbleCat, whatWeHaveStockCat }: any) => {
    const { t } = useTranslation();
    const [tabIndex, setTabIndex] = useState(0);
    return (<section className='what-we-have'>
        <div className='mcontainer'>
            <div className='sec-header'>
                <h1 className="title">{data.title}</h1>
                <p className='description'>{data.subtitle}</p>
            </div>
            <div className='weh-content'>
                <div className='weh-content-imgs'>
                    {tabIndex === 0 ?
                        (whatWeHaveMarbleCat.photos && whatWeHaveMarbleCat.photos.map((th: any, i: any) => {
                            return (
                                <div className='what-we-have-object-fit-image' key={i}>
                                    <a href={th.link} target='_blank'><img src={th.image} alt={"Tile" + i} /></a>
                                </div>
                            )
                        })
                        ) : (whatWeHaveStockCat.photos && whatWeHaveStockCat.photos.map((th: any, i: any) => {
                            return (
                                <div className='what-we-have-object-fit-image' key={i}>
                                    <a href={th.link} target='_blank'><img src={th.image} alt={"Tile" + i} /></a>
                                </div>
                            )
                        })
                        )
                    }
                </div>
                <div className='weh-content-data'>
                    <div className='c-tabs'>
                        <div className='tab-header'>
                            {data.sub_section && data.sub_section.map((th: any, i: any) => {
                                return (<button type="button" onClick={() => setTabIndex(i)} className={tabIndex == i ? "active" : ""} key={i}>{th.title}</button>)
                            })}
                        </div>
                        {data.sub_section && data.sub_section.map((th: any, i: any) => {
                            return (<div className={'tab-body ' + (tabIndex == i ? "active" : "")} key={i}>
                                <p>{th.sub_title}</p>
                                <ul className='categories-list justify-content-between'>
                                    {tabIndex === 0 ?
                                        (whatWeHaveMarbleCat.categories && whatWeHaveMarbleCat.categories.map((th: any, i: any) => {
                                            return (<li key={i} className='me-md-2'><a href={th.link} target='_blank' className='text-hover-link'>{th.name.length > 15 ? `${th.name.slice(0, 15)}...` : t(th.name)}</a></li>)
                                        })
                                        ) : (whatWeHaveStockCat.categories && whatWeHaveStockCat.categories.map((th: any, i: any) => {
                                            return (<li key={i} className='me-md-2'><a href={th.link} target='_blank' className='text-hover-link'>{th.name.length > 15 ? `${th.name.slice(0, 15)}...` : t(th.name)}</a></li>)
                                        })
                                        )
                                    }
                                </ul>
                            </div>)
                        })}
                    </div>
                </div>
            </div>
            <a type="button" className="btn btn-home-page-primary" href={process.env.REACT_APP_LIVE_SITE_BASE_URL + data.link} target='_blank'>{t("View More")}</a>
        </div>
    </section >)
}
// Premium Content section start 
const PremiumContent = ({ data }: any) => {
    return (<section className='premium-content'>
        <div className='sec-header'>
            <h1 className="title">{data.title}</h1>
            <p className='description'>{data.subtitle}</p>
        </div>

        <div className='p-content-container' style={{ background: 'url(' + data.image + ') no-repeat', backgroundSize: "cover", backgroundPosition: "center" }}>
            <div className='p-content-card' dangerouslySetInnerHTML={{ __html: data.description }}>
            </div>
        </div>
    </section >)
}

// Earning level based on and download section start 
const EarningLevels = ({ data, earningLevelStock, earningLevelMarble }: any) => {
    const { t } = useTranslation();
    const [tabIndex, setTabIndex] = useState(0);
    const [levelIndex, setlevelIndex] = useState(0);
    return (<section className='earning-levels'>
        <div className='mcontainer'>
            <div className='sec-header'>
                <h1 className="title">{data.title}</h1>
                <p className='description'>{data.subtitle}</p>
            </div>

            <div className='el-content'>
                <div className='el-content-diagram'>
                    <div className='c-tabs'>
                        <div className='tab-header'>
                            <button type="button" onClick={() => {
                                setTabIndex(0)
                                setlevelIndex(0)
                            }} className={(tabIndex == 0 ? "active" : "")}>{t(earningLevelMarble.type)}</button>
                            <button type="button" onClick={() => {
                                setTabIndex(1)
                                setlevelIndex(0)
                            }} className={(tabIndex == 1 ? "active" : "")}>{t(earningLevelStock.type)}</button>
                        </div>
                        <div className='tab-body'>
                            <div className="level-stepper">
                                {tabIndex === 0 ?
                                    (earningLevelMarble && earningLevelMarble.all_levels.map((th: any, i: number) => {
                                        return (<span key={i} className={levelIndex == i ? 'active' : ""} onClick={() => {
                                            setlevelIndex(i)
                                        }} id={levelIndex > i || levelIndex === i ? 'level-progress' : ''}></span>)
                                    })) :
                                    (earningLevelStock && earningLevelStock.all_levels.map((th: any, i: number) => {
                                        return (<span key={i} className={levelIndex == i ? 'active' : ""} onClick={() => {
                                            setlevelIndex(i)
                                        }} id={levelIndex > i || levelIndex === i ? 'level-progress' : ''} ></span>)
                                    }))
                                }
                            </div>
                            {tabIndex === 0 ?
                                (earningLevelMarble && earningLevelMarble.all_levels.map((th: any, i: number) => {
                                    return (
                                        levelIndex === i ? (<>
                                            <label key={i}>{t(th.next_level)}</label>
                                            <label className='badge-white'>{t(th.description)}</label>
                                        </>) : (<></>)
                                    )
                                }))
                                :
                                (earningLevelStock && earningLevelStock.all_levels.map((th: any, i: number) => {
                                    return (
                                        levelIndex === i ? (<>
                                            <label>{t(th.next_level)}</label>
                                            <label className='badge-white'>{t(th.description)}</label>
                                        </>) : (<></>)
                                    )
                                }))
                            }
                        </div>
                    </div>
                </div>
                <div className='el-content-data'>
                    <h3 className='title'>{t(tabIndex === 0 ? earningLevelMarble.type : earningLevelStock.type)}</h3>
                    <label>{t("Level")}</label>
                    <ol>
                        {tabIndex === 0 ?
                            (earningLevelMarble.level.map((th: any, i: number) => {
                                return (<li key={i}>{t(th)}</li>)
                            }))
                            :
                            (earningLevelStock.level.map((th: any, i: number) => {
                                return (<li key={i}>{t(th)}</li>)
                            }))
                        }
                    </ol>
                </div>
            </div>
        </div>
        <Link type="button" className="btn btn-home-page-secondary" to={ROUTE_REGISTER}>{t("Sign Up")}</Link>
    </section >)
}
// Artist Name Session start 
const ArtistNames = ({ data, artistnamessection }: any) => {
    const { t } = useTranslation();
    return (<section className='artist-names'>
        <div className='mcontainer'>
            <div className='sec-header'>
                <h1 className="title">{data.title}</h1>
                <p className='description'>{data.subtitle}</p>
            </div>

            <div className='thumbs-artists'>
                {artistnamessection && artistnamessection.map((th: any, i: number) => {
                    return (<div className='thumb' key={i}>
                        <img src={th.image} alt='Artist1' />
                        <h3 className='title'>{t(th.name)}</h3>
                        <p className='description'>{t(th.description)}</p>
                    </div>)
                })}
            </div>

        </div>
    </section >)
}

// Payout section start 
const Payout = ({ data }: any) => {
    const { t } = useTranslation();
    return (<section className='payout'>
        <div className='mcontainer'>
            <div className='sec-header'>
                <h1 className="title">{data.title}</h1>
                <p className='description'>{data.subtitle}</p>
            </div>
            <div className='payout-content' dangerouslySetInnerHTML={{ __html: data.description }}></div>
            <Link type="button" className="btn btn-home-page-primary" to={data.link}>{t("Signup Now")}</Link>
        </div>
    </section >)
}

// Global Market section start 
const GlobalMarket = ({ data }: any) => {
    return (<section className='global-market'>
        <div className='mcontainer'>
            <div className='sec-header'>
                <h1 className="title">{data.title}</h1>
                <p className='description'>{data.subtitle}</p>
            </div>

            <div className='thumbs-global-market'>
                {data.sub_section && data.sub_section.map((th: any, i: any) => {
                    return (<a className='thumb text-black' key={i} href={th.link}>
                        <img src={th.image} alt={th.image} />
                        <div className='gm-content-card'>
                            <h3 className='title'>{th.title}</h3>
                            <p className='description'>{th.sub_title}</p>
                        </div>
                    </a>)
                })}
            </div>
        </div>
    </section >)
}

// Create Submit Earn section start  
const CreateSubmitEarn = ({ data }: any) => {
    const { t } = useTranslation();
    return (<section className='create-submit-earn'>
        <div className='mcontainer'>
            <div className='sec-header'>
                <h1 className="title">{data.title}</h1>
                <p className='description'>{data.subtitle}</p>
            </div>

            <div className='thumbs-cse'>
                {data.sub_section && data.sub_section.map((th: any, i: any) => {
                    return (<a className='thumb text-black' key={i} href={th.link}>
                        <div className='imgContainer'>
                            <img src={th.image} alt={th.image} />
                        </div>
                        <h3 className='title'>{t(th.title)}</h3>
                    </a>)
                })}
            </div>
            <Link to={data.link} className="btn btn-home-page-secondary">{t("Signup Now")}</Link>
        </div>
    </section >)
}

// Refer and Earn section start 
const ReferEarn = ({ data }: any) => {
    const { t } = useTranslation();
    return (<section className='refer-earn'>
        <div className='mcontainer'>
            <div className='sec-header'>
                <h1 className="title">{data.title}</h1>
                <p className='description d-flex justify-content-center' dangerouslySetInnerHTML={{ __html: data.description }} >
                </p>
            </div>

            <div className='thumbs-re'>
                {data.sub_section && data.sub_section.map((th: any, i: any) => {
                    return (<a className='thumb text-black' key={i} href={th.link}>
                        <img src={th.image} alt={th.image} />
                        <h3 className='title'>{th.title}</h3>
                        <p className='description'>{th.sub_title}</p>
                    </a>)
                })}

            </div>
            <Link to={data.link} className="btn btn-home-page-primary">{t("Learn More")}</Link>
        </div>
    </section >)
}

// How to Apply section start 
const HowToApply = ({ data }: any) => {
    const { t } = useTranslation();
    return (<section className='how-to-apply'>
        <div className='sec-header'>
            <h1 className="title">{data.title}</h1>
            <p className='description'>{data.subtitle}</p>
        </div>
        <div className='d-flex flex-column align-items-center w-75' dangerouslySetInnerHTML={{ __html: data.description }} ></div>
        {/* <h3 className="note">{data.desc}</h3> */}
        <Link to={ROUTE_REGISTER} className="btn btn-home-page-secondary">{t("Let's start")}</Link>
    </section >)
}
// Easy to Use Tools section start 
const EasyToUseTools = ({ data }: any) => {
    const { t } = useTranslation();
    return (<section className='easy-to-use-tools'>
        <div className='sec-header'>
            <h1 className="title">{data.title}</h1>
            <div dangerouslySetInnerHTML={{ __html: data.subtitle }}></div>
        </div>

        <div className='bg-etut' style={{ background: 'url(' + data.image + ') no-repeat', backgroundSize: "cover", backgroundPosition: "center" }}></div>

        <Link className="btn btn-home-page-primary" to={data.link}>{t("Signup Now")}</Link>
    </section >)
}

// Trending Images section start
const TrendingImages = ({ data, trendingImagesSection }: any) => {
    const { t } = useTranslation();
    return (<section className='tending-images'>
        <div className='sec-header'>
            <h1 className="title">{data.title}</h1>
            <p className='description'>{data.subtitle}</p>
        </div>
        <ul className='image-grid'>
            {trendingImagesSection && trendingImagesSection.map((th: any, i: Number) => {
                return (
                    <li>
                        <div className='home-object-fit-img'>
                            <a href={th.link} target='_blank'>
                                <UndefinedImage src={IMAGE_CDN_PUBLIC + th.image} alt={th.image} />
                            </a>
                        </div>
                    </li>
                )
            })}
        </ul>
        <a href={process.env.REACT_APP_LIVE_SITE_BASE_URL + data.link} target='_blank' className="btn btn-home-page-secondary">{t("See More")}</a>
    </section >)
}

// FAQ section start 
class FAQ extends React.Component<any, any> {
    state = { rows: [], data: [] as any }
    componentDidMount(): void {
        let updatedRows = [] as any;
        axios.get(API_GET_FAQ_HELP).then((response: any) => {
            this.setState({ data: response.data.data });
            response.data.data.map((th: any, i: number) => (
                th["topic"].map((data: any, i: number) => (
                    data.qa ? (
                        data.qa.map((row: any, i: number) => {
                            updatedRows.push(row);
                        })
                    ) : null
                ))
            ))
            this.setState({ rows: updatedRows });
        }).catch((error) => {
            this.setState({ showLoader: false });
            showErrors(error, false, this.props.t);
        });

    }
    expandItem = (rowIndex: number) => {
        const rows: any[] = this.state.rows;
        rows[rowIndex]["expanded"] = !rows[rowIndex]["expanded"];
        this.setState({ rows });
    }

    needHelpClick = () => {
        localStorage.setItem("needHelpApply", 'y');
        // window.location.href = ROUTE_LOGIN;
    };
    // const referralClick = () => {
    //     localStorage.setItem("referral", 'y');
    //     // window.location.href = ROUTE_LOGIN;
    // };

    render() {
        const { data } = this.props;
        return (<section className='faq'>
            <div className='sec-header'>
                <h1 className="title">{data.title}</h1>
                <p className='description'>{data.subtitle}</p>
            </div>
            <div className='caccordium'>
                {this.state.rows ? (this.state.rows?.map((th: any, i: number) => {
                    return (
                        <div className={`a-item ${th.expanded ? "active" : ""}`} key={i}>
                            <h3 className='title' onClick={() => this.expandItem(i)}>
                                <span>{th.question}</span>
                                <button type="button"></button>
                            </h3>
                            <p className='description'>{th.answer}</p>
                        </div>
                    );
                })
                ) : null}
            </div>
            <Link to={ROUTE_LOGIN} className="btn btn-home-page-primary" onClick={this.needHelpClick}>{this.props.t("Need help?")}</Link>
        </section >)
    }
}

//  testimonial section start 
const Testimonial: React.FC<TestimonialProps> = ({ data, whatPeopleSayTestimonial }) => {
    const { t } = useTranslation();
    const [currentIndex, setCurrentIndex] = useState(0);
    const scRef: any = useRef(null);

    const handleSlideChange = (swiper: any) => {
        setCurrentIndex(swiper.realIndex);
    };

    const renderSlides = () => {
        return whatPeopleSayTestimonial.map((th: any, i: number) => (
            <SwiperSlide key={i} className={currentIndex === i ? 'active' : ''}>
                <div className='content'>
                    <p dangerouslySetInnerHTML={{ __html: t(th.description) }}></p>
                    <h3 className='name'>{t(th.name)}</h3>
                    <label>{t(th.designation)}</label>
                </div>
            </SwiperSlide>
        ));
    };

    return (
        <section className='testimonial' style={{ background: `url(${data.image}) no-repeat center cover` }}>
            <div className='t-content-container'>
                <div className='theader'>
                    <h3 className="title">{t(data.title)}</h3>
                    <p className='description'>{t(data.subtitle)}</p>

                    <div className='nav-btns'>
                        <button type="button" className="swiper-button-prev" disabled={currentIndex === 0}>&larr;</button>
                        <button type="button" className="swiper-button-next" disabled={currentIndex === whatPeopleSayTestimonial.length - 1}>&rarr;</button>
                    </div>
                </div>

                <div className='comments-list-container' ref={scRef}>
                    <ul className='comments-list'>
                        <Swiper
                            modules={[Navigation, Pagination, Scrollbar, A11y]}
                            navigation={{
                                nextEl: '.swiper-button-next',
                                prevEl: '.swiper-button-prev',
                            }}
                            breakpoints={{
                                5100: {
                                    slidesPerView: 6,
                                    spaceBetween: 40,
                                },
                                3800: {
                                    slidesPerView: 6,
                                    spaceBetween: 40,
                                },
                                2500: {
                                    slidesPerView: 5,
                                    spaceBetween: 40,
                                },
                                1920: {
                                    slidesPerView:4,
                                    spaceBetween: 40,
                                },
                                1440: {
                                    slidesPerView: 4,
                                    spaceBetween: 40,
                                },
                                1024: {
                                    slidesPerView: 3,
                                    spaceBetween: 40,
                                },
                                992: {
                                    slidesPerView: 2,
                                    spaceBetween: 40,
                                },
                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 10,
                                },
                                320: {
                                    slidesPerView: 1,
                                    spaceBetween: 20,
                                },
                            }}
                            on={{
                                slideChange: handleSlideChange,
                            }}>
                            {renderSlides()}
                        </Swiper>
                    </ul>
                </div>
            </div>
        </section >
    );
};

const ContributorBlog = ({ data, blogPostData }: any) => {
    const [imageFormat, setImageFormat] = useState(1);
    const { t } = useTranslation();
    let counter = 0;
    return (
        <section className='contributor-blog'>
            <div className="mcontainer">
                <div className='sec-header'>
                    <h3 className="title">{t(data.title)}</h3>
                    <p className='description'>{t(data.subtitle)}</p>
                </div>

                <ul className='b-list'>
                    {blogPostData !== "" && blogPostData.map((th: any, i: number) => {
                        counter++; // Increment the counter on each iteration
                        const isLeftShow = counter % 2 === 1;
                        return (
                            <>
                                {i < 3 ?
                                    <li key={i}>
                                        {isLeftShow ?
                                            (
                                                <>
                                                    <div className='imgContainer'>
                                                        <img src={th.yoast_head_json.og_image[0].url} alt="blog-1" />
                                                    </div>
                                                    <div className='dataContainer'>
                                                        <h3 className='title'><a className='text-dark' target='_blank' href={th.link}>{t(th.title.rendered)}</a></h3>
                                                        <div className='description' dangerouslySetInnerHTML={{ __html: th.excerpt.rendered }}>
                                                        </div>
                                                        <a href={th.link} target='_blank' className="btn-home-page-read-more" >{t("Read More")}</a>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div className='dataContainer'>
                                                        <h3 className='title'><a className='text-dark' target='_blank' href={th.link}>{t(th.title.rendered)}</a></h3>
                                                        <div className='description' dangerouslySetInnerHTML={{ __html: th.excerpt.rendered }}>
                                                        </div>
                                                        <a href={th.link} target='_blank' className="btn-home-page-read-more" >{t("Read More")}</a>
                                                    </div>
                                                    <div className='imgContainer'>
                                                        <img src={th.yoast_head_json.og_image[0].url} alt="blog-1" />
                                                    </div>
                                                </>
                                            )
                                        }
                                    </li>
                                    : (<></>)}
                            </>)
                    })}
                </ul>
                <a className="btn btn-home-page-secondary" target='_blank' href={process.env.REACT_APP_LIVE_SITE_BASE_URL + data.link}>{t('See More')}</a>
            </div>
        </section>
    );
}
// start earning section 
const StartEarningToday = ({ data }: any) => {
    const { t } = useTranslation();
    return (<section className='start-earning-today'>
        <div className='set-content'>
            <h1 className="title">{data.title}</h1>
            <p className='description-big'>{data.subtitle}</p>
            <Link className="btn btn-home-page-primary" to={data.link}>{t("Join now")}</Link>
        </div>
    </section >)
}
// start footer section 
const Footer = () => {
    const { t } = useTranslation();

    const referralClick = () => {
        localStorage.setItem("referral", 'y');
        // window.location.href = ROUTE_LOGIN;
    };
    const [data, setData] = useState(0);
    setTimeout(() => {
        setData(1);
    }, 2000)
    return (<footer className='page-footer'>
        <div className="mcontainer">
            <div className='content-main'>
                <div className='col-about'>
                    <h3 className='title'>{t('Cliqstock')}</h3>
                    <p className='description'>
                        {t('Address')}
                        <br />
                        <span>SURVEY NO. 38, PLOT NO. 6, BEHIND VISHAL FURNITURE TRAJPAR MORBI</span>
                        <br />
                        <span>Email:<a href="mailto:info@cliqstock.com" className='text-decoration-none text-dark'>info@cliqstock.com</a></span>
                    </p>
                    <a href={process.env.REACT_APP_CONTACT_US} className=''>{t('Contact Us')}</a>
                </div>
                <div className="menu-link">
                    <div className='col-links-user'>
                        <ul>
                            <li><a href={process.env.REACT_APP_PURCHASE_DESIGN} className='text-hover-link'>{t('Purchase Design')}</a></li>
                            <li><a href="/register" className='text-hover-link'>{t('Signup')}</a></li>
                            <li><Link to={ROUTE_LOGIN} onClick={referralClick} className='text-hover-link'>{t('Referral Program')}</Link></li>
                            <li><a href={process.env.REACT_APP_SUBMIT_GUIDELINE} className='text-hover-link'>{t('Submit Guideline')}</a></li>
                        </ul>
                    </div>
                    <div className='col-links-site'>
                        <ul>
                            <li><a href={process.env.REACT_APP_EARNING_BREAKDOWN} className='text-hover-link'>{t('Earning Breakdown')}</a></li>
                            <li><a href={process.env.REACT_APP_CONTACT_US} className='text-hover-link'>{t('Cliqstock API')}</a></li>
                            <li><a href={process.env.REACT_APP_BLOG} className='text-hover-link'>{t('Blog')}</a></li>
                            <li><a href={process.env.REACT_APP_ABOUT_US} className='text-hover-link'>{t('About Us')}</a></li>
                        </ul>
                    </div>
                </div>

                <div className='col-social'>
                    <LanguageDropdownGuest class="footer-language" />
                    <div className='social-icons'>
                        <h3 className='title'>{t("Follow Us On")}</h3>
                        <ul>
                            <li><a href="https://www.linkedin.com/company/cliqstock/" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16">
                                <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
                            </svg></a></li>
                            <li><a href="https://www.facebook.com/cliqstock" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="22" fill="currentColor" className="bi bi-facebook" viewBox="0 0 16 16">
                                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951" />
                            </svg></a></li>
                            <li><a href="https://www.instagram.com/cliqstock/" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="22" fill="currentColor" className="bi bi-instagram" viewBox="0 0 16 16">
                                <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
                            </svg></a></li>
                            <li><a href="https://www.youtube.com/channel/UCHZYbGZBkDVTTfUqV6_7YFQ" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="22" fill="currentColor" className="bi bi-youtube" viewBox="0 0 16 16">
                                <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z" />
                            </svg></a></li>
                            <li><a href="https://twitter.com/cliqstock" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="22" fill="currentColor" className="bi bi-twitter" viewBox="0 0 16 16">
                                <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334q.002-.211-.006-.422A6.7 6.7 0 0 0 16 3.542a6.7 6.7 0 0 1-1.889.518 3.3 3.3 0 0 0 1.447-1.817 6.5 6.5 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.32 9.32 0 0 1-6.767-3.429 3.29 3.29 0 0 0 1.018 4.382A3.3 3.3 0 0 1 .64 6.575v.045a3.29 3.29 0 0 0 2.632 3.218 3.2 3.2 0 0 1-.865.115 3 3 0 0 1-.614-.057 3.28 3.28 0 0 0 3.067 2.277A6.6 6.6 0 0 1 .78 13.58a6 6 0 0 1-.78-.045A9.34 9.34 0 0 0 5.026 15" />
                            </svg></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <hr className='page-seperator' />
            <div className='content-copyright'>
                {/* <p>&copy; {new Date().getFullYear()} - All rights reserved by cliqstock.com</p> */}
                <p className="copyright-text">&copy; Copyright <span id="date">{new Date().getFullYear()}</span> <a href="https://www.cliqstock.com" className='text-hover-link'> CLQSTOCK </a> All
                    Rights Reserved.</p>
                <ul>
                    <li><a href="https://www.cliqstock.com/privacy-policy" target="_blank" className='text-hover-link'>{t("Privacy Policy")}</a></li>
                    <li><a href="https://www.cliqstock.com/terms-of-use" target="_blank" className='text-hover-link'>{t("Terms")}</a></li>
                    <li><a href="https://www.cliqstock.com/terms-of-service" target="_blank" className='text-hover-link'>{t("Terms of Service")}</a></li>
                    <li><a href="https://www.cliqstock.com/contributor-terms-of-service" target="_blank" className='text-hover-link'>{t("Contributor Terms of Service")}</a></li>
                    <li><a href="https://www.cliqstock.com/copyright" target="_blank" className='text-hover-link'>{t("Copyright")}</a></li>
                </ul>
            </div>
        </div>
    </footer>)
}
// end footer section 

class Home extends React.Component {
    state = {languageslodibg:true, data: [] as any, showLoader: false, blogPost: [] as any }
    componentDidMount(): void {
        this.blogPost();
        this.doLoadData();
        setTimeout(() => {
            this.setState({ languageslodibg: false });
        }, 1500);
    }

    doLoadData = () => {
        if (this.state.showLoader) { return; }
        this.setState({ showLoader: true }, () => {
            axios.get(API_HOME)
                .then((response: any) => {
                    this.setState({ data: response.data.data });
                }).catch((error) => {
                    this.setState({ showLoader: false });
                    showError(error, false);
                });
        });
    }

    blogPost = () => {
        const apiUrl = process.env.REACT_APP_BLOG_API_URL;
        if (!apiUrl) {
            console.error("Blog API URL is not defined.");
            return;
        }
        this.setState({ showLoader: true }, () => {
            axios.get(apiUrl)
                .then((response: any) => {
                    this.setState({ blogPost: response.data, showLoader: false });
                }).catch((error) => {
                    this.setState({ showLoader: false });
                    // showErroSSr(error, false);
                });
        });
    }
    getObject = (data: any) => {
        if (!data) {
            { }
        }
        if (!Array.isArray(data)) {
            return data;
        }
        if (data.length === 0) {
            return {};
        }
        return data[0];

    }
    render() {
        if (this.state.showLoader) {
            return (<div className='main-page-loading'></div>)
        }
        return (<div className='main-page'>
            <Helmet><title>Home - {process.env.REACT_APP_NAME}</title></Helmet>
            <TopHeader />
            {this.state.data.slider ? <SlideShow data={this.state.data.slider} /> : <></>}
            {this.state.data.why_cliqstock ? <Why data={this.getObject(this.state.data.why_cliqstock)} /> : <></>}
            {this.state.data.who_become_a_contributor ? <BecomeContributor data={this.getObject(this.state.data.who_become_a_contributor)} /> : <></>}
            {this.state.data.diff_cliqstock_others ? <Comparison data={this.getObject(this.state.data.diff_cliqstock_others)} /> : <></>}
            {this.state.data.reason_to_work_with_us ? <Reasons data={this.getObject(this.state.data.reason_to_work_with_us)} /> : <></>}
            {this.state.data.how_it_works ? <HowWorks data={this.getObject(this.state.data.how_it_works)} fileSupport={this.state.data.file_support} /> : <></>}
            <hr className='page-seperator' />
            {this.state.data.what_we_have ? <WhatWeHave data={this.getObject(this.state.data.what_we_have)} whatWeHaveMarbleCat={this.getObject(this.state.data.what_we_have_marble_cat)} whatWeHaveStockCat={this.getObject(this.state.data.what_we_have_stock_cat)} /> : <></>}
            {this.state.data.premium_content ? <PremiumContent data={this.getObject(this.state.data.premium_content)} /> : <></>}
            {this.state.data.earning_level ? <EarningLevels data={this.getObject(this.state.data.earning_level)} earningLevelMarble={this.state.data.earning_level_marble} earningLevelStock={this.state.data.earning_level_stock} /> : <></>}
            <hr className='page-seperator' />
            {this.state.data.artist_names ? <ArtistNames data={this.getObject(this.state.data.artist_names)} artistnamessection={this.state.data.artist_names_section} /> : <></>}
            {this.state.data.payout ? <Payout data={this.getObject(this.state.data.payout)} /> : <></>}
            {this.state.data.global_market ? <GlobalMarket data={this.getObject(this.state.data.global_market)} /> : <></>}
            {this.state.data.create_submit_earn ? <CreateSubmitEarn data={this.getObject(this.state.data.create_submit_earn)} /> : <></>}
            {this.state.data.refer_earn ? <ReferEarn data={this.getObject(this.state.data.refer_earn)} /> : <></>}
            {this.state.data.how_to_apply ? <HowToApply data={this.getObject(this.state.data.how_to_apply)} /> : <></>}
            {this.state.data.easy_to_use ? <EasyToUseTools data={this.getObject(this.state.data.easy_to_use)} /> : <></>}
            <hr className='page-seperator' />
            {this.state.data.trending_images ? <TrendingImages data={this.getObject(this.state.data.trending_images)} trendingImagesSection={this.state.data.trending_images_section} /> : <></>}
            {this.state.data.faq ? <FAQ {...this.props} data={this.getObject(this.state.data.faq)} /> : <></>}
            {this.state.data.what_people_say ? <Testimonial data={this.getObject(this.state.data.what_people_say)} whatPeopleSayTestimonial={this.state.data.what_people_say_testimonial} /> : <></>}
            {this.state.data.blog ? <ContributorBlog data={this.getObject(this.state.data.blog)} blogPostData={this.state.blogPost} /> : <></>}
            {this.state.data.start_earning ? <StartEarningToday data={this.getObject(this.state.data.start_earning)} /> : <></>}
            {!this.state.languageslodibg ? <Footer /> : <></>}
        </div>)
    }
}

export default withRouter(Home);