import React, { useState } from 'react';
// import undefined from "../assets/images/undefind-1.png";
import undefined from "../assets/images/350x400.jpeg";
import { IMAGE_CDN_PUBLIC_WATERMARK } from './config';
const UndefinedImage = ({ src, alt, fallbackSrc, width, height, className, style, pstyle }: any) => {
    const [error, setError] = useState(false);
    const [newerror, setNewError] = useState(false);
    const handleError = (event: any) => {
        event.target.src = fallbackSrc;
        setError(true)
    };
    const handleErrors = (event: any) => {
        setError(false)
        setNewError(true)
    }
    if (error === false && newerror === false) {
        return <img src={src} alt={alt} width={width} height={height} style={style} className={className} onError={handleError} />;
    } else if (error === true && newerror === false) {
        return <img src={IMAGE_CDN_PUBLIC_WATERMARK + alt} alt={alt} width={width} height={height} style={style} className={className} onError={handleErrors} />;
    } else {
        return <img src={undefined} alt={alt} width={width} height={height} style={style} className={className} />;
    }
};

export default UndefinedImage;