import { concatJsonObject } from "../common/jsonutils";
import { SESSION_DATA } from "./constants";

const AppSession = () => {
    let loggedInData = localStorage.getItem(SESSION_DATA) as any;
    let loggedInUser: any = null;

    if (loggedInData && loggedInData != "undefined") {
        loggedInUser = JSON.parse(loggedInData);
        if (loggedInUser["created_at"]) {
            const dateString = loggedInUser["created_at"];
            const date = new Date(dateString);
            loggedInUser["created_date"] = date.getDate();
            loggedInUser["created_month"] = (date.getMonth() + 1);
            loggedInUser["created_year"] = (date.getFullYear());
        }
    }

    const setSession = (sessionData: object) => {
        loggedInUser = sessionData;
        localStorage.setItem(SESSION_DATA, JSON.stringify(sessionData));
        return true;
    };

    const getSession = () => loggedInUser;
    const isLoggedIn = () => {
        return loggedInUser !== null;
    };
    const getToken = () => isLoggedIn() ? loggedInUser.token : null;
    // logout function  
    const logout = () => {
        const i18nLanguage = localStorage.getItem('I18N_LANGUAGE');
        localStorage.clear();
        if (i18nLanguage) {
            localStorage.setItem('I18N_LANGUAGE', i18nLanguage);
        }
        loggedInUser = null;
        return true;
    }
    const getCurrencyObject = () => isLoggedIn() ? { currency: loggedInUser.currency, currency_symbol: loggedInUser.currency_symbol } : {};
    const updateSessionData = (profileData: any) => {
        if (isLoggedIn()) {
            loggedInUser = concatJsonObject(loggedInUser, profileData);
            setSession(loggedInUser);
        }
    }

    // Add a new method to update a specific property in the session
    const updateSessionProperty = (propertyName: any, propertyValue: any) => {
        const sessionData = getSession();
        sessionData[propertyName] = propertyValue;
        updateSessionData(sessionData);
    };

    return {
        logout,
        isLoggedIn,
        getToken,
        getSession,
        setSession,
        updateSessionData,
        getCurrencyObject,
        updateSessionProperty
    }
};
export default AppSession();