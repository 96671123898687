import axios from "../network/axios";

import React from "react";
import logo from '../assets/images/logo.png';
import "../assets/scss/app-guest.scss";
import { API_GET_BANNER_IMAGE } from "../network/apis";
import { showError } from "../common/error-handler";
import { IMAGE_CDN_PUBLIC, IMAGE_CDN_PUBLIC_WATERMARK } from "../common/config";
import { faListCheck } from "@fortawesome/free-solid-svg-icons";
import image from "../assets/images/login-signup-bg.jpg"
export default class GeustLayout extends React.Component<any, any> {
    state = { data: {} as any, showLoader: faListCheck, imageUrl: "" }
    componentDidMount(): void {
        this.doLoadBanner();
    }
    // login and register page banner 
    doLoadBanner = () => {
        this.setState({ showLoader: true }, () => {
            axios.get(API_GET_BANNER_IMAGE)
                .then((response: any) => {
                    this.setState({ data: response.data["data"], showLoader: false, imageUrl: IMAGE_CDN_PUBLIC + response.data["data"].image });
                }).catch((error) => {
                    this.setState({ showLoader: false });
                    showError(error, false);
                });
        });
    }
    handleError = (event: any) => {
        this.setState({ imageUrl: image })
    }
    render() {
        return (<React.Fragment>
            <img src={IMAGE_CDN_PUBLIC + this.state.data.image} alt={this.state.data.image} onError={this.handleError} style={{ display: "none" }} />
            <div className="loginsignup-bg" style={this.state.imageUrl ? { background: "url('" + this.state.imageUrl + "') no-repeat", backgroundSize: "cover !important" } : {}}>
                <div className="logomain"><a href={process.env.REACT_APP_BASE_URL}><img src={logo} width="227" alt="" /></a></div>
                <div className="signup-box">
                    {this.props.children}</div>
                <div className="artistname"><a href={this.state.data.link}><span>By</span> {this.state.data.firstname + " " + this.state.data.lastname}</a></div>
            </div>
        </React.Fragment>)
    }
}