import React, { useEffect, useState } from 'react';
import axios from './network/axios';
import LoadingBar from 'react-top-loading-bar';

const TopProgressbar = (WrappedComponent: any) => {
  const WithProgressBar = (props: any) => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
      const requestInterceptor = axios.interceptors.request.use(
        (config) => {
          setProgress(30);
          return config;
        },
        (error) => {
          setProgress(0);
          return Promise.reject(error);
        }
      );

      const responseInterceptor = axios.interceptors.response.use(
        (response) => {
          setProgress(100);
          return response;
        },
        (error) => {
          setProgress(0);
          return Promise.reject(error);
        }
      );

      return () => {
        axios.interceptors.request.eject(requestInterceptor);
        axios.interceptors.response.eject(responseInterceptor);
      };
    }, []);

    return (
      <>
        <LoadingBar
          color='#ec2956'
          progress={progress}
          transitionTime={500}
          height={3}
          waitingTime={3000}
          shadow={true}
          onLoaderFinished={() => setProgress(0)}
        />
        <WrappedComponent {...props} />
      </>
    );
  };

  return WithProgressBar;
};

export default TopProgressbar;
