export const CountryCodes = [
    {
        "id": 1,
        "code": "AF",
        "nicename": "AFGHANISTAN",
        "name": "Afghanistan",
        "dial_code": 93
    },
    {
        "id": 2,
        "code": "AL",
        "nicename": "ALBANIA",
        "name": "Albania",
        "dial_code": 355
    },
    {
        "id": 3,
        "code": "DZ",
        "nicename": "ALGERIA",
        "name": "Algeria",
        "dial_code": 213
    },
    {
        "id": 4,
        "code": "AS",
        "nicename": "AMERICAN SAMOA",
        "name": "American Samoa",
        "dial_code": 1684
    },
    {
        "id": 5,
        "code": "AD",
        "nicename": "ANDORRA",
        "name": "Andorra",
        "dial_code": 376
    },
    {
        "id": 6,
        "code": "AO",
        "nicename": "ANGOLA",
        "name": "Angola",
        "dial_code": 244
    },
    {
        "id": 7,
        "code": "AI",
        "nicename": "ANGUILLA",
        "name": "Anguilla",
        "dial_code": 1264
    },
    {
        "id": 8,
        "code": "AQ",
        "nicename": "ANTARCTICA",
        "name": "Antarctica",
        "dial_code": 0
    },
    {
        "id": 9,
        "code": "AG",
        "nicename": "ANTIGUA AND BARBUDA",
        "name": "Antigua and Barbuda",
        "dial_code": 1268
    },
    {
        "id": 10,
        "code": "AR",
        "nicename": "ARGENTINA",
        "name": "Argentina",
        "dial_code": 54
    },
    {
        "id": 11,
        "code": "AM",
        "nicename": "ARMENIA",
        "name": "Armenia",
        "dial_code": 374
    },
    {
        "id": 12,
        "code": "AW",
        "nicename": "ARUBA",
        "name": "Aruba",
        "dial_code": 297
    },
    {
        "id": 13,
        "code": "AU",
        "nicename": "AUSTRALIA",
        "name": "Australia",
        "dial_code": 61
    },
    {
        "id": 14,
        "code": "AT",
        "nicename": "AUSTRIA",
        "name": "Austria",
        "dial_code": 43
    },
    {
        "id": 15,
        "code": "AZ",
        "nicename": "AZERBAIJAN",
        "name": "Azerbaijan",
        "dial_code": 994
    },
    {
        "id": 16,
        "code": "BS",
        "nicename": "BAHAMAS",
        "name": "Bahamas",
        "dial_code": 1242
    },
    {
        "id": 17,
        "code": "BH",
        "nicename": "BAHRAIN",
        "name": "Bahrain",
        "dial_code": 973
    },
    {
        "id": 18,
        "code": "BD",
        "nicename": "BANGLADESH",
        "name": "Bangladesh",
        "dial_code": 880
    },
    {
        "id": 19,
        "code": "BB",
        "nicename": "BARBADOS",
        "name": "Barbados",
        "dial_code": 1246
    },
    {
        "id": 20,
        "code": "BY",
        "nicename": "BELARUS",
        "name": "Belarus",
        "dial_code": 375
    },
    {
        "id": 21,
        "code": "BE",
        "nicename": "BELGIUM",
        "name": "Belgium",
        "dial_code": 32
    },
    {
        "id": 22,
        "code": "BZ",
        "nicename": "BELIZE",
        "name": "Belize",
        "dial_code": 501
    },
    {
        "id": 23,
        "code": "BJ",
        "nicename": "BENIN",
        "name": "Benin",
        "dial_code": 229
    },
    {
        "id": 24,
        "code": "BM",
        "nicename": "BERMUDA",
        "name": "Bermuda",
        "dial_code": 1441
    },
    {
        "id": 25,
        "code": "BT",
        "nicename": "BHUTAN",
        "name": "Bhutan",
        "dial_code": 975
    },
    {
        "id": 26,
        "code": "BO",
        "nicename": "BOLIVIA",
        "name": "Bolivia",
        "dial_code": 591
    },
    {
        "id": 27,
        "code": "BA",
        "nicename": "BOSNIA AND HERZEGOVINA",
        "name": "Bosnia and Herzegovina",
        "dial_code": 387
    },
    {
        "id": 28,
        "code": "BW",
        "nicename": "BOTSWANA",
        "name": "Botswana",
        "dial_code": 267
    },
    {
        "id": 29,
        "code": "BV",
        "nicename": "BOUVET ISLAND",
        "name": "Bouvet Island",
        "dial_code": 0
    },
    {
        "id": 30,
        "code": "BR",
        "nicename": "BRAZIL",
        "name": "Brazil",
        "dial_code": 55
    },
    {
        "id": 31,
        "code": "IO",
        "nicename": "BRITISH INDIAN OCEAN TERRITORY",
        "name": "British Indian Ocean Territory",
        "dial_code": 246
    },
    {
        "id": 32,
        "code": "BN",
        "nicename": "BRUNEI DARUSSALAM",
        "name": "Brunei Darussalam",
        "dial_code": 673
    },
    {
        "id": 33,
        "code": "BG",
        "nicename": "BULGARIA",
        "name": "Bulgaria",
        "dial_code": 359
    },
    {
        "id": 34,
        "code": "BF",
        "nicename": "BURKINA FASO",
        "name": "Burkina Faso",
        "dial_code": 226
    },
    {
        "id": 35,
        "code": "BI",
        "nicename": "BURUNDI",
        "name": "Burundi",
        "dial_code": 257
    },
    {
        "id": 36,
        "code": "KH",
        "nicename": "CAMBODIA",
        "name": "Cambodia",
        "dial_code": 855
    },
    {
        "id": 37,
        "code": "CM",
        "nicename": "CAMEROON",
        "name": "Cameroon",
        "dial_code": 237
    },
    {
        "id": 38,
        "code": "CA",
        "nicename": "CANADA",
        "name": "Canada",
        "dial_code": 1
    },
    {
        "id": 39,
        "code": "CV",
        "nicename": "CAPE VERDE",
        "name": "Cape Verde",
        "dial_code": 238
    },
    {
        "id": 40,
        "code": "KY",
        "nicename": "CAYMAN ISLANDS",
        "name": "Cayman Islands",
        "dial_code": 1345
    },
    {
        "id": 41,
        "code": "CF",
        "nicename": "CENTRAL AFRICAN REPUBLIC",
        "name": "Central African Republic",
        "dial_code": 236
    },
    {
        "id": 42,
        "code": "TD",
        "nicename": "CHAD",
        "name": "Chad",
        "dial_code": 235
    },
    {
        "id": 43,
        "code": "CL",
        "nicename": "CHILE",
        "name": "Chile",
        "dial_code": 56
    },
    {
        "id": 44,
        "code": "CN",
        "nicename": "CHINA",
        "name": "China",
        "dial_code": 86
    },
    {
        "id": 45,
        "code": "CX",
        "nicename": "CHRISTMAS ISLAND",
        "name": "Christmas Island",
        "dial_code": 61
    },
    {
        "id": 46,
        "code": "CC",
        "nicename": "COCOS (KEELING) ISLANDS",
        "name": "Cocos (Keeling) Islands",
        "dial_code": 672
    },
    {
        "id": 47,
        "code": "CO",
        "nicename": "COLOMBIA",
        "name": "Colombia",
        "dial_code": 57
    },
    {
        "id": 48,
        "code": "KM",
        "nicename": "COMOROS",
        "name": "Comoros",
        "dial_code": 269
    },
    {
        "id": 49,
        "code": "CG",
        "nicename": "CONGO",
        "name": "Congo",
        "dial_code": 242
    },
    {
        "id": 50,
        "code": "CD",
        "nicename": "CONGO, THE DEMOCRATIC REPUBLIC OF THE",
        "name": "Congo, the Democratic Republic of the",
        "dial_code": 242
    },
    {
        "id": 51,
        "code": "CK",
        "nicename": "COOK ISLANDS",
        "name": "Cook Islands",
        "dial_code": 682
    },
    {
        "id": 52,
        "code": "CR",
        "nicename": "COSTA RICA",
        "name": "Costa Rica",
        "dial_code": 506
    },
    {
        "id": 53,
        "code": "CI",
        "nicename": "COTE D'IVOIRE",
        "name": "Cote D'Ivoire",
        "dial_code": 225
    },
    {
        "id": 54,
        "code": "HR",
        "nicename": "CROATIA",
        "name": "Croatia",
        "dial_code": 385
    },
    {
        "id": 55,
        "code": "CU",
        "nicename": "CUBA",
        "name": "Cuba",
        "dial_code": 53
    },
    {
        "id": 56,
        "code": "CY",
        "nicename": "CYPRUS",
        "name": "Cyprus",
        "dial_code": 357
    },
    {
        "id": 57,
        "code": "CZ",
        "nicename": "CZECH REPUBLIC",
        "name": "Czech Republic",
        "dial_code": 420
    },
    {
        "id": 58,
        "code": "DK",
        "nicename": "DENMARK",
        "name": "Denmark",
        "dial_code": 45
    },
    {
        "id": 59,
        "code": "DJ",
        "nicename": "DJIBOUTI",
        "name": "Djibouti",
        "dial_code": 253
    },
    {
        "id": 60,
        "code": "DM",
        "nicename": "DOMINICA",
        "name": "Dominica",
        "dial_code": 1767
    },
    {
        "id": 61,
        "code": "DO",
        "nicename": "DOMINICAN REPUBLIC",
        "name": "Dominican Republic",
        "dial_code": 1809
    },
    {
        "id": 62,
        "code": "EC",
        "nicename": "ECUADOR",
        "name": "Ecuador",
        "dial_code": 593
    },
    {
        "id": 63,
        "code": "EG",
        "nicename": "EGYPT",
        "name": "Egypt",
        "dial_code": 20
    },
    {
        "id": 64,
        "code": "SV",
        "nicename": "EL SALVADOR",
        "name": "El Salvador",
        "dial_code": 503
    },
    {
        "id": 65,
        "code": "GQ",
        "nicename": "EQUATORIAL GUINEA",
        "name": "Equatorial Guinea",
        "dial_code": 240
    },
    {
        "id": 66,
        "code": "ER",
        "nicename": "ERITREA",
        "name": "Eritrea",
        "dial_code": 291
    },
    {
        "id": 67,
        "code": "EE",
        "nicename": "ESTONIA",
        "name": "Estonia",
        "dial_code": 372
    },
    {
        "id": 68,
        "code": "ET",
        "nicename": "ETHIOPIA",
        "name": "Ethiopia",
        "dial_code": 251
    },
    {
        "id": 69,
        "code": "FK",
        "nicename": "FALKLAND ISLANDS (MALVINAS)",
        "name": "Falkland Islands (Malvinas)",
        "dial_code": 500
    },
    {
        "id": 70,
        "code": "FO",
        "nicename": "FAROE ISLANDS",
        "name": "Faroe Islands",
        "dial_code": 298
    },
    {
        "id": 71,
        "code": "FJ",
        "nicename": "FIJI",
        "name": "Fiji",
        "dial_code": 679
    },
    {
        "id": 72,
        "code": "FI",
        "nicename": "FINLAND",
        "name": "Finland",
        "dial_code": 358
    },
    {
        "id": 73,
        "code": "FR",
        "nicename": "FRANCE",
        "name": "France",
        "dial_code": 33
    },
    {
        "id": 74,
        "code": "GF",
        "nicename": "FRENCH GUIANA",
        "name": "French Guiana",
        "dial_code": 594
    },
    {
        "id": 75,
        "code": "PF",
        "nicename": "FRENCH POLYNESIA",
        "name": "French Polynesia",
        "dial_code": 689
    },
    {
        "id": 76,
        "code": "TF",
        "nicename": "FRENCH SOUTHERN TERRITORIES",
        "name": "French Southern Territories",
        "dial_code": 0
    },
    {
        "id": 77,
        "code": "GA",
        "nicename": "GABON",
        "name": "Gabon",
        "dial_code": 241
    },
    {
        "id": 78,
        "code": "GM",
        "nicename": "GAMBIA",
        "name": "Gambia",
        "dial_code": 220
    },
    {
        "id": 79,
        "code": "GE",
        "nicename": "GEORGIA",
        "name": "Georgia",
        "dial_code": 995
    },
    {
        "id": 80,
        "code": "DE",
        "nicename": "GERMANY",
        "name": "Germany",
        "dial_code": 49
    },
    {
        "id": 81,
        "code": "GH",
        "nicename": "GHANA",
        "name": "Ghana",
        "dial_code": 233
    },
    {
        "id": 82,
        "code": "GI",
        "nicename": "GIBRALTAR",
        "name": "Gibraltar",
        "dial_code": 350
    },
    {
        "id": 83,
        "code": "GR",
        "nicename": "GREECE",
        "name": "Greece",
        "dial_code": 30
    },
    {
        "id": 84,
        "code": "GL",
        "nicename": "GREENLAND",
        "name": "Greenland",
        "dial_code": 299
    },
    {
        "id": 85,
        "code": "GD",
        "nicename": "GRENADA",
        "name": "Grenada",
        "dial_code": 1473
    },
    {
        "id": 86,
        "code": "GP",
        "nicename": "GUADELOUPE",
        "name": "Guadeloupe",
        "dial_code": 590
    },
    {
        "id": 87,
        "code": "GU",
        "nicename": "GUAM",
        "name": "Guam",
        "dial_code": 1671
    },
    {
        "id": 88,
        "code": "GT",
        "nicename": "GUATEMALA",
        "name": "Guatemala",
        "dial_code": 502
    },
    {
        "id": 89,
        "code": "GN",
        "nicename": "GUINEA",
        "name": "Guinea",
        "dial_code": 224
    },
    {
        "id": 90,
        "code": "GW",
        "nicename": "GUINEA-BISSAU",
        "name": "Guinea-Bissau",
        "dial_code": 245
    },
    {
        "id": 91,
        "code": "GY",
        "nicename": "GUYANA",
        "name": "Guyana",
        "dial_code": 592
    },
    {
        "id": 92,
        "code": "HT",
        "nicename": "HAITI",
        "name": "Haiti",
        "dial_code": 509
    },
    {
        "id": 93,
        "code": "HM",
        "nicename": "HEARD ISLAND AND MCDONALD ISLANDS",
        "name": "Heard Island and Mcdonald Islands",
        "dial_code": 0
    },
    {
        "id": 94,
        "code": "VA",
        "nicename": "HOLY SEE (VATICAN CITY STATE)",
        "name": "Holy See (Vatican City State)",
        "dial_code": 39
    },
    {
        "id": 95,
        "code": "HN",
        "nicename": "HONDURAS",
        "name": "Honduras",
        "dial_code": 504
    },
    {
        "id": 96,
        "code": "HK",
        "nicename": "HONG KONG",
        "name": "Hong Kong",
        "dial_code": 852
    },
    {
        "id": 97,
        "code": "HU",
        "nicename": "HUNGARY",
        "name": "Hungary",
        "dial_code": 36
    },
    {
        "id": 98,
        "code": "IS",
        "nicename": "ICELAND",
        "name": "Iceland",
        "dial_code": 354
    },
    {
        "id": 99,
        "code": "IN",
        "nicename": "INDIA",
        "name": "India",
        "dial_code": 91
    },
    {
        "id": 100,
        "code": "ID",
        "nicename": "INDONESIA",
        "name": "Indonesia",
        "dial_code": 62
    },
    {
        "id": 101,
        "code": "IR",
        "nicename": "IRAN, ISLAMIC REPUBLIC OF",
        "name": "Iran, Islamic Republic of",
        "dial_code": 98
    },
    {
        "id": 102,
        "code": "IQ",
        "nicename": "IRAQ",
        "name": "Iraq",
        "dial_code": 964
    },
    {
        "id": 103,
        "code": "IE",
        "nicename": "IRELAND",
        "name": "Ireland",
        "dial_code": 353
    },
    {
        "id": 104,
        "code": "IL",
        "nicename": "ISRAEL",
        "name": "Israel",
        "dial_code": 972
    },
    {
        "id": 105,
        "code": "IT",
        "nicename": "ITALY",
        "name": "Italy",
        "dial_code": 39
    },
    {
        "id": 106,
        "code": "JM",
        "nicename": "JAMAICA",
        "name": "Jamaica",
        "dial_code": 1876
    },
    {
        "id": 107,
        "code": "JP",
        "nicename": "JAPAN",
        "name": "Japan",
        "dial_code": 81
    },
    {
        "id": 108,
        "code": "JO",
        "nicename": "JORDAN",
        "name": "Jordan",
        "dial_code": 962
    },
    {
        "id": 109,
        "code": "KZ",
        "nicename": "KAZAKHSTAN",
        "name": "Kazakhstan",
        "dial_code": 7
    },
    {
        "id": 110,
        "code": "KE",
        "nicename": "KENYA",
        "name": "Kenya",
        "dial_code": 254
    },
    {
        "id": 111,
        "code": "KI",
        "nicename": "KIRIBATI",
        "name": "Kiribati",
        "dial_code": 686
    },
    {
        "id": 112,
        "code": "KP",
        "nicename": "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF",
        "name": "Korea, Democratic People's Republic of",
        "dial_code": 850
    },
    {
        "id": 113,
        "code": "KR",
        "nicename": "KOREA, REPUBLIC OF",
        "name": "Korea, Republic of",
        "dial_code": 82
    },
    {
        "id": 114,
        "code": "KW",
        "nicename": "KUWAIT",
        "name": "Kuwait",
        "dial_code": 965
    },
    {
        "id": 115,
        "code": "KG",
        "nicename": "KYRGYZSTAN",
        "name": "Kyrgyzstan",
        "dial_code": 996
    },
    {
        "id": 116,
        "code": "LA",
        "nicename": "LAO PEOPLE'S DEMOCRATIC REPUBLIC",
        "name": "Lao People's Democratic Republic",
        "dial_code": 856
    },
    {
        "id": 117,
        "code": "LV",
        "nicename": "LATVIA",
        "name": "Latvia",
        "dial_code": 371
    },
    {
        "id": 118,
        "code": "LB",
        "nicename": "LEBANON",
        "name": "Lebanon",
        "dial_code": 961
    },
    {
        "id": 119,
        "code": "LS",
        "nicename": "LESOTHO",
        "name": "Lesotho",
        "dial_code": 266
    },
    {
        "id": 120,
        "code": "LR",
        "nicename": "LIBERIA",
        "name": "Liberia",
        "dial_code": 231
    },
    {
        "id": 121,
        "code": "LY",
        "nicename": "LIBYAN ARAB JAMAHIRIYA",
        "name": "Libyan Arab Jamahiriya",
        "dial_code": 218
    },
    {
        "id": 122,
        "code": "LI",
        "nicename": "LIECHTENSTEIN",
        "name": "Liechtenstein",
        "dial_code": 423
    },
    {
        "id": 123,
        "code": "LT",
        "nicename": "LITHUANIA",
        "name": "Lithuania",
        "dial_code": 370
    },
    {
        "id": 124,
        "code": "LU",
        "nicename": "LUXEMBOURG",
        "name": "Luxembourg",
        "dial_code": 352
    },
    {
        "id": 125,
        "code": "MO",
        "nicename": "MACAO",
        "name": "Macao",
        "dial_code": 853
    },
    {
        "id": 126,
        "code": "MK",
        "nicename": "MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF",
        "name": "Macedonia, the Former Yugoslav Republic of",
        "dial_code": 389
    },
    {
        "id": 127,
        "code": "MG",
        "nicename": "MADAGASCAR",
        "name": "Madagascar",
        "dial_code": 261
    },
    {
        "id": 128,
        "code": "MW",
        "nicename": "MALAWI",
        "name": "Malawi",
        "dial_code": 265
    },
    {
        "id": 129,
        "code": "MY",
        "nicename": "MALAYSIA",
        "name": "Malaysia",
        "dial_code": 60
    },
    {
        "id": 130,
        "code": "MV",
        "nicename": "MALDIVES",
        "name": "Maldives",
        "dial_code": 960
    },
    {
        "id": 131,
        "code": "ML",
        "nicename": "MALI",
        "name": "Mali",
        "dial_code": 223
    },
    {
        "id": 132,
        "code": "MT",
        "nicename": "MALTA",
        "name": "Malta",
        "dial_code": 356
    },
    {
        "id": 133,
        "code": "MH",
        "nicename": "MARSHALL ISLANDS",
        "name": "Marshall Islands",
        "dial_code": 692
    },
    {
        "id": 134,
        "code": "MQ",
        "nicename": "MARTINIQUE",
        "name": "Martinique",
        "dial_code": 596
    },
    {
        "id": 135,
        "code": "MR",
        "nicename": "MAURITANIA",
        "name": "Mauritania",
        "dial_code": 222
    },
    {
        "id": 136,
        "code": "MU",
        "nicename": "MAURITIUS",
        "name": "Mauritius",
        "dial_code": 230
    },
    {
        "id": 137,
        "code": "YT",
        "nicename": "MAYOTTE",
        "name": "Mayotte",
        "dial_code": 269
    },
    {
        "id": 138,
        "code": "MX",
        "nicename": "MEXICO",
        "name": "Mexico",
        "dial_code": 52
    },
    {
        "id": 139,
        "code": "FM",
        "nicename": "MICRONESIA, FEDERATED STATES OF",
        "name": "Micronesia, Federated States of",
        "dial_code": 691
    },
    {
        "id": 140,
        "code": "MD",
        "nicename": "MOLDOVA, REPUBLIC OF",
        "name": "Moldova, Republic of",
        "dial_code": 373
    },
    {
        "id": 141,
        "code": "MC",
        "nicename": "MONACO",
        "name": "Monaco",
        "dial_code": 377
    },
    {
        "id": 142,
        "code": "MN",
        "nicename": "MONGOLIA",
        "name": "Mongolia",
        "dial_code": 976
    },
    {
        "id": 143,
        "code": "MS",
        "nicename": "MONTSERRAT",
        "name": "Montserrat",
        "dial_code": 1664
    },
    {
        "id": 144,
        "code": "MA",
        "nicename": "MOROCCO",
        "name": "Morocco",
        "dial_code": 212
    },
    {
        "id": 145,
        "code": "MZ",
        "nicename": "MOZAMBIQUE",
        "name": "Mozambique",
        "dial_code": 258
    },
    {
        "id": 146,
        "code": "MM",
        "nicename": "MYANMAR",
        "name": "Myanmar",
        "dial_code": 95
    },
    {
        "id": 147,
        "code": "NA",
        "nicename": "NAMIBIA",
        "name": "Namibia",
        "dial_code": 264
    },
    {
        "id": 148,
        "code": "NR",
        "nicename": "NAURU",
        "name": "Nauru",
        "dial_code": 674
    },
    {
        "id": 149,
        "code": "NP",
        "nicename": "NEPAL",
        "name": "Nepal",
        "dial_code": 977
    },
    {
        "id": 150,
        "code": "NL",
        "nicename": "NETHERLANDS",
        "name": "Netherlands",
        "dial_code": 31
    },
    {
        "id": 151,
        "code": "AN",
        "nicename": "NETHERLANDS ANTILLES",
        "name": "Netherlands Antilles",
        "dial_code": 599
    },
    {
        "id": 152,
        "code": "NC",
        "nicename": "NEW CALEDONIA",
        "name": "New Caledonia",
        "dial_code": 687
    },
    {
        "id": 153,
        "code": "NZ",
        "nicename": "NEW ZEALAND",
        "name": "New Zealand",
        "dial_code": 64
    },
    {
        "id": 154,
        "code": "NI",
        "nicename": "NICARAGUA",
        "name": "Nicaragua",
        "dial_code": 505
    },
    {
        "id": 155,
        "code": "NE",
        "nicename": "NIGER",
        "name": "Niger",
        "dial_code": 227
    },
    {
        "id": 156,
        "code": "NG",
        "nicename": "NIGERIA",
        "name": "Nigeria",
        "dial_code": 234
    },
    {
        "id": 157,
        "code": "NU",
        "nicename": "NIUE",
        "name": "Niue",
        "dial_code": 683
    },
    {
        "id": 158,
        "code": "NF",
        "nicename": "NORFOLK ISLAND",
        "name": "Norfolk Island",
        "dial_code": 672
    },
    {
        "id": 159,
        "code": "MP",
        "nicename": "NORTHERN MARIANA ISLANDS",
        "name": "Northern Mariana Islands",
        "dial_code": 1670
    },
    {
        "id": 160,
        "code": "NO",
        "nicename": "NORWAY",
        "name": "Norway",
        "dial_code": 47
    },
    {
        "id": 161,
        "code": "OM",
        "nicename": "OMAN",
        "name": "Oman",
        "dial_code": 968
    },
    {
        "id": 162,
        "code": "PK",
        "nicename": "PAKISTAN",
        "name": "Pakistan",
        "dial_code": 92
    },
    {
        "id": 163,
        "code": "PW",
        "nicename": "PALAU",
        "name": "Palau",
        "dial_code": 680
    },
    {
        "id": 164,
        "code": "PS",
        "nicename": "PALESTINIAN TERRITORY, OCCUPIED",
        "name": "Palestinian Territory, Occupied",
        "dial_code": 970
    },
    {
        "id": 165,
        "code": "PA",
        "nicename": "PANAMA",
        "name": "Panama",
        "dial_code": 507
    },
    {
        "id": 166,
        "code": "PG",
        "nicename": "PAPUA NEW GUINEA",
        "name": "Papua New Guinea",
        "dial_code": 675
    },
    {
        "id": 167,
        "code": "PY",
        "nicename": "PARAGUAY",
        "name": "Paraguay",
        "dial_code": 595
    },
    {
        "id": 168,
        "code": "PE",
        "nicename": "PERU",
        "name": "Peru",
        "dial_code": 51
    },
    {
        "id": 169,
        "code": "PH",
        "nicename": "PHILIPPINES",
        "name": "Philippines",
        "dial_code": 63
    },
    {
        "id": 170,
        "code": "PN",
        "nicename": "PITCAIRN",
        "name": "Pitcairn",
        "dial_code": 0
    },
    {
        "id": 171,
        "code": "PL",
        "nicename": "POLAND",
        "name": "Poland",
        "dial_code": 48
    },
    {
        "id": 172,
        "code": "PT",
        "nicename": "PORTUGAL",
        "name": "Portugal",
        "dial_code": 351
    },
    {
        "id": 173,
        "code": "PR",
        "nicename": "PUERTO RICO",
        "name": "Puerto Rico",
        "dial_code": 1787
    },
    {
        "id": 174,
        "code": "QA",
        "nicename": "QATAR",
        "name": "Qatar",
        "dial_code": 974
    },
    {
        "id": 175,
        "code": "RE",
        "nicename": "REUNION",
        "name": "Reunion",
        "dial_code": 262
    },
    {
        "id": 176,
        "code": "RO",
        "nicename": "ROMANIA",
        "name": "Romania",
        "dial_code": 40
    },
    {
        "id": 177,
        "code": "RU",
        "nicename": "RUSSIAN FEDERATION",
        "name": "Russian Federation",
        "dial_code": 70
    },
    {
        "id": 178,
        "code": "RW",
        "nicename": "RWANDA",
        "name": "Rwanda",
        "dial_code": 250
    },
    {
        "id": 179,
        "code": "SH",
        "nicename": "SAINT HELENA",
        "name": "Saint Helena",
        "dial_code": 290
    },
    {
        "id": 180,
        "code": "KN",
        "nicename": "SAINT KITTS AND NEVIS",
        "name": "Saint Kitts and Nevis",
        "dial_code": 1869
    },
    {
        "id": 181,
        "code": "LC",
        "nicename": "SAINT LUCIA",
        "name": "Saint Lucia",
        "dial_code": 1758
    },
    {
        "id": 182,
        "code": "PM",
        "nicename": "SAINT PIERRE AND MIQUELON",
        "name": "Saint Pierre and Miquelon",
        "dial_code": 508
    },
    {
        "id": 183,
        "code": "VC",
        "nicename": "SAINT VINCENT AND THE GRENADINES",
        "name": "Saint Vincent and the Grenadines",
        "dial_code": 1784
    },
    {
        "id": 184,
        "code": "WS",
        "nicename": "SAMOA",
        "name": "Samoa",
        "dial_code": 684
    },
    {
        "id": 185,
        "code": "SM",
        "nicename": "SAN MARINO",
        "name": "San Marino",
        "dial_code": 378
    },
    {
        "id": 186,
        "code": "ST",
        "nicename": "SAO TOME AND PRINCIPE",
        "name": "Sao Tome and Principe",
        "dial_code": 239
    },
    {
        "id": 187,
        "code": "SA",
        "nicename": "SAUDI ARABIA",
        "name": "Saudi Arabia",
        "dial_code": 966
    },
    {
        "id": 188,
        "code": "SN",
        "nicename": "SENEGAL",
        "name": "Senegal",
        "dial_code": 221
    },
    {
        "id": 189,
        "code": "CS",
        "nicename": "SERBIA AND MONTENEGRO",
        "name": "Serbia and Montenegro",
        "dial_code": 381
    },
    {
        "id": 190,
        "code": "SC",
        "nicename": "SEYCHELLES",
        "name": "Seychelles",
        "dial_code": 248
    },
    {
        "id": 191,
        "code": "SL",
        "nicename": "SIERRA LEONE",
        "name": "Sierra Leone",
        "dial_code": 232
    },
    {
        "id": 192,
        "code": "SG",
        "nicename": "SINGAPORE",
        "name": "Singapore",
        "dial_code": 65
    },
    {
        "id": 193,
        "code": "SK",
        "nicename": "SLOVAKIA",
        "name": "Slovakia",
        "dial_code": 421
    },
    {
        "id": 194,
        "code": "SI",
        "nicename": "SLOVENIA",
        "name": "Slovenia",
        "dial_code": 386
    },
    {
        "id": 195,
        "code": "SB",
        "nicename": "SOLOMON ISLANDS",
        "name": "Solomon Islands",
        "dial_code": 677
    },
    {
        "id": 196,
        "code": "SO",
        "nicename": "SOMALIA",
        "name": "Somalia",
        "dial_code": 252
    },
    {
        "id": 197,
        "code": "ZA",
        "nicename": "SOUTH AFRICA",
        "name": "South Africa",
        "dial_code": 27
    },
    {
        "id": 198,
        "code": "GS",
        "nicename": "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS",
        "name": "South Georgia and the South Sandwich Islands",
        "dial_code": 0
    },
    {
        "id": 199,
        "code": "ES",
        "nicename": "SPAIN",
        "name": "Spain",
        "dial_code": 34
    },
    {
        "id": 200,
        "code": "LK",
        "nicename": "SRI LANKA",
        "name": "Sri Lanka",
        "dial_code": 94
    },
    {
        "id": 201,
        "code": "SD",
        "nicename": "SUDAN",
        "name": "Sudan",
        "dial_code": 249
    },
    {
        "id": 202,
        "code": "SR",
        "nicename": "SURINAME",
        "name": "Suriname",
        "dial_code": 597
    },
    {
        "id": 203,
        "code": "SJ",
        "nicename": "SVALBARD AND JAN MAYEN",
        "name": "Svalbard and Jan Mayen",
        "dial_code": 47
    },
    {
        "id": 204,
        "code": "SZ",
        "nicename": "SWAZILAND",
        "name": "Swaziland",
        "dial_code": 268
    },
    {
        "id": 205,
        "code": "SE",
        "nicename": "SWEDEN",
        "name": "Sweden",
        "dial_code": 46
    },
    {
        "id": 206,
        "code": "CH",
        "nicename": "SWITZERLAND",
        "name": "Switzerland",
        "dial_code": 41
    },
    {
        "id": 207,
        "code": "SY",
        "nicename": "SYRIAN ARAB REPUBLIC",
        "name": "Syrian Arab Republic",
        "dial_code": 963
    },
    {
        "id": 208,
        "code": "TW",
        "nicename": "TAIWAN, PROVINCE OF CHINA",
        "name": "Taiwan, Province of China",
        "dial_code": 886
    },
    {
        "id": 209,
        "code": "TJ",
        "nicename": "TAJIKISTAN",
        "name": "Tajikistan",
        "dial_code": 992
    },
    {
        "id": 210,
        "code": "TZ",
        "nicename": "TANZANIA, UNITED REPUBLIC OF",
        "name": "Tanzania, United Republic of",
        "dial_code": 255
    },
    {
        "id": 211,
        "code": "TH",
        "nicename": "THAILAND",
        "name": "Thailand",
        "dial_code": 66
    },
    {
        "id": 212,
        "code": "TL",
        "nicename": "TIMOR-LESTE",
        "name": "Timor-Leste",
        "dial_code": 670
    },
    {
        "id": 213,
        "code": "TG",
        "nicename": "TOGO",
        "name": "Togo",
        "dial_code": 228
    },
    {
        "id": 214,
        "code": "TK",
        "nicename": "TOKELAU",
        "name": "Tokelau",
        "dial_code": 690
    },
    {
        "id": 215,
        "code": "TO",
        "nicename": "TONGA",
        "name": "Tonga",
        "dial_code": 676
    },
    {
        "id": 216,
        "code": "TT",
        "nicename": "TRINIDAD AND TOBAGO",
        "name": "Trinidad and Tobago",
        "dial_code": 1868
    },
    {
        "id": 217,
        "code": "TN",
        "nicename": "TUNISIA",
        "name": "Tunisia",
        "dial_code": 216
    },
    {
        "id": 218,
        "code": "TR",
        "nicename": "TURKEY",
        "name": "Turkey",
        "dial_code": 90
    },
    {
        "id": 219,
        "code": "TM",
        "nicename": "TURKMENISTAN",
        "name": "Turkmenistan",
        "dial_code": 7370
    },
    {
        "id": 220,
        "code": "TC",
        "nicename": "TURKS AND CAICOS ISLANDS",
        "name": "Turks and Caicos Islands",
        "dial_code": 1649
    },
    {
        "id": 221,
        "code": "TV",
        "nicename": "TUVALU",
        "name": "Tuvalu",
        "dial_code": 688
    },
    {
        "id": 222,
        "code": "UG",
        "nicename": "UGANDA",
        "name": "Uganda",
        "dial_code": 256
    },
    {
        "id": 223,
        "code": "UA",
        "nicename": "UKRAINE",
        "name": "Ukraine",
        "dial_code": 380
    },
    {
        "id": 224,
        "code": "AE",
        "nicename": "UNITED ARAB EMIRATES",
        "name": "United Arab Emirates",
        "dial_code": 971
    },
    {
        "id": 225,
        "code": "GB",
        "nicename": "UNITED KINGDOM",
        "name": "United Kingdom",
        "dial_code": 44
    },
    {
        "id": 226,
        "code": "US",
        "nicename": "UNITED STATES",
        "name": "United States",
        "dial_code": 1
    },
    {
        "id": 227,
        "code": "UM",
        "nicename": "UNITED STATES MINOR OUTLYING ISLANDS",
        "name": "United States Minor Outlying Islands",
        "dial_code": 1
    },
    {
        "id": 228,
        "code": "UY",
        "nicename": "URUGUAY",
        "name": "Uruguay",
        "dial_code": 598
    },
    {
        "id": 229,
        "code": "UZ",
        "nicename": "UZBEKISTAN",
        "name": "Uzbekistan",
        "dial_code": 998
    },
    {
        "id": 230,
        "code": "VU",
        "nicename": "VANUATU",
        "name": "Vanuatu",
        "dial_code": 678
    },
    {
        "id": 231,
        "code": "VE",
        "nicename": "VENEZUELA",
        "name": "Venezuela",
        "dial_code": 58
    },
    {
        "id": 232,
        "code": "VN",
        "nicename": "VIET NAM",
        "name": "Viet Nam",
        "dial_code": 84
    },
    {
        "id": 233,
        "code": "VG",
        "nicename": "VIRGIN ISLANDS, BRITISH",
        "name": "Virgin Islands, British",
        "dial_code": 1284
    },
    {
        "id": 234,
        "code": "VI",
        "nicename": "VIRGIN ISLANDS, U.S.",
        "name": "Virgin Islands, U.s.",
        "dial_code": 1340
    },
    {
        "id": 235,
        "code": "WF",
        "nicename": "WALLIS AND FUTUNA",
        "name": "Wallis and Futuna",
        "dial_code": 681
    },
    {
        "id": 236,
        "code": "EH",
        "nicename": "WESTERN SAHARA",
        "name": "Western Sahara",
        "dial_code": 212
    },
    {
        "id": 237,
        "code": "YE",
        "nicename": "YEMEN",
        "name": "Yemen",
        "dial_code": 967
    },
    {
        "id": 238,
        "code": "ZM",
        "nicename": "ZAMBIA",
        "name": "Zambia",
        "dial_code": 260
    },
    {
        "id": 239,
        "code": "ZW",
        "nicename": "ZIMBABWE",
        "name": "Zimbabwe",
        "dial_code": 263
    }

];