// export const DEFAULT_CURRENCY_SYMBOL = "₹";
export const REFERRAL_CUSTOMER_COMMISSION = {
    image: { value: 0.04, valueType: "₹" },
    video: { value: 10, valueType: "%" }
}
export const REFERRAL_BUYER_COMMISSION = {
    image: { value: 20, valueType: "%", max: 200, maxValueType: "₹" },
    video: { value: 20, valueType: "%", max: 200, maxValueType: "₹" }
}
export const DEFAULT_ACTIVE_VALUE = "y";
export const DEFAULT_NOT_ACTIVE_VALUE = "n";
export const DEFAULT_IMAGE_CDN = process.env.REACT_APP_IMAGE_CDN;
export const DEFAULT_IMAGE_STAGE_CDN = process.env.REACT_APP_IMAGE_STAGE_CDN;
export const IMAGE_CDN_PROFILE_PIC = DEFAULT_IMAGE_CDN + "/stage/contributor/profilepic/";
export const IMAGE_CDN_PUBLIC = DEFAULT_IMAGE_STAGE_CDN + "/";
export const IMAGE_CDN_PUBLIC_WATERMARK = DEFAULT_IMAGE_STAGE_CDN + "/assets/contributor/watermark/";
//assets/contributor/watermark/
// export const DEFAULT_PRIVATE_CDN = process.env.REACT_APP_PRIVATE_CDN + "/";

export const CHECK_2FA_STATUS = true;


export const DEFAULT_DUMMY_VERIFICATION_TOKEN = "-0000-es-0000";
export const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;

