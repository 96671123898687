import React, { useEffect, useState, useRef } from "react"
import { Link } from "react-router-dom";
import logoContributor from '../assets/images/logo-contributor.svg';
import "../assets/scss/app-authenticated.scss";
import { formatDecimal } from "../common/mathutils";
import { doLogout } from '../network/axios';
import axios from '../network/axios';
import { ROUTE_EARNING_SUMMARY, ROUTE_MY_REFERRALS, ROUTE_PAYMENT_HISTORY, ROUTE_TAX_CENTER, ROUTE_CONTRIBUTOR_BLOG, ROUTE_ACCOUNT_SETTINGS, ROUTE_PUBLIC_PROFILE, ROUTE_NOTIFICATION_SETTINGS, ROUTE_TOP_PERFORMERS, ROUTE_DASHBOARD, ROUTE_HELP, ROUTE_LOGIN, ROUTE_LOGOUT, ROUTE_CONTACT, ROUTE_FEEDBACK, ROUTE_INVOICES, ROUTE_THE_SHORT_LIST, ROUTE_UPLOAD, ROUTE_MOU, ROUTE_SUBMIT_CONTENT_KEYWORD_TOOL, ROUTE_SUBMIT_CONTENT, ROUTE_PENDING_CONTENT, ROUTE_REVIEW_CONTENT, ROUTE_PORTFOLIO, ROUTE_CUSTOMER_REF_PHOTOS, ROUTE_PAYOUT, ROUTE_PROCESS_CONTENT } from "../network/urls";
import AppSession from "../security/AppSession";
import LanguageDropdown from "./common/LanguageDropdown";
import { useTranslation } from "react-i18next";
import { API_FOOTER, API_GET_EARNINGS_AMOUNT, API_GET_IP_INFO, API_GET_LANGUAGE } from "../network/apis";
import { showError } from '../common/error-handler';
import { DEFAULT_ACTIVE_VALUE, DEFAULT_IMAGE_STAGE_CDN } from "../common/config";
import {  getProfile, getfooter } from "../pages/store/apiaction";
import { connect } from "react-redux";
import withRouter from "../common/withRouter";
import { toast } from "react-toastify";
const getRandomColor = () => {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}
const getInitials = (name: any) => {
    if (typeof name === 'string') {
        let initials;
        const nameSplit = name.split(" ");
        const nameLength = nameSplit.length;
        if (nameLength > 1) {
            initials =
                nameSplit[0].substring(0, 1) +
                nameSplit[nameLength - 1].substring(0, 1);
        } else if (nameLength === 1) {
            initials = nameSplit[0].substring(0, 1);
        } else return;

        return initials.toUpperCase();
    }
};
const createImageFromInitials = (size: any, name: any, color: any) => {
    if (name == null) return;
    name = getInitials(name);
    const canvas = document.createElement('canvas')
    const context: any = canvas.getContext('2d')
    canvas.width = canvas.height = size;
    // Draw circular clip path
    context.beginPath();
    context.arc(size / 2, size / 2, size / 2 - 1.5, 0, 2 * Math.PI); // Adjusting for the 1.5-pixel border
    context.lineWidth = 20; // Border width
    context.strokeStyle = "#ec2956"; // Border color
    context.stroke();
    context.clip();
    // Draw white background
    context.fillStyle = `#000`;
    context.fillRect(0, 0, size, size);
    // Draw initials
    context.fillStyle = "#ec2956"; // Default to red color if color is not provided
    context.textBaseline = 'middle';
    context.textAlign = 'center';
    context.font = `${size / 1.8}px Roboto,sans-serif`;
    context.fillText(name, size / 2, size / 1.8);
    return canvas.toDataURL();
};
const subMenuesData = [
    {
        title: "Home", class: "bi bi-activity", svg: <svg style={{ "color": "rgb(128, 131, 137)" }} xmlns="http://www.w3.org/2000/svg" width="25" height="22" fill="currentColor" className="bi bi-house mb-1" viewBox="0 0 16 16"> <path fillRule="evenodd" d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z" fill="#808389" /> <path fillRule="evenodd" d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z" fill="#808389" /> </svg>, path: ROUTE_DASHBOARD, menues: [], position: "TOP"
    },
    {
        title: "Earnings", icon: "bi-coin", svg: <svg style={{ color: "rgb(128, 131, 137)" }} xmlns="http://www.w3.org/2000/svg" width="25" height="22" fill="currentColor" className="bi bi-coin mb-1" viewBox="0 0 16 16"> <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9H5.5zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518l.087.02z" fill="#808389"></path> <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" fill="#808389"></path> <path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zm0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12z" fill="#808389"></path> </svg>, menues: [
            { title: "Earnings summary", path: ROUTE_EARNING_SUMMARY, svg: <svg xmlns="http://www.w3.org/2000/svg" width="25" height="22" fill="currentColor" className="bi bi-coin me-3" viewBox="0 0 16 16"> <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9H5.5zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518l.087.02z" /> <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" /> <path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zm0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12z" /> </svg> },
            { title: "Invoices", path: ROUTE_INVOICES, svg: <svg xmlns="http://www.w3.org/2000/svg" width="25" height="22" fill="currentColor" className="bi bi-card-checklist me-3" viewBox="0 0 16 16"> <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" /> <path d="M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0zM7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z" /></svg> },
            { title: "Tax center", path: ROUTE_TAX_CENTER, svg: <svg xmlns="http://www.w3.org/2000/svg" width="25" height="22" fill="currentColor" className="bi bi-card-list me-3" viewBox="0 0 16 16"> <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" /> <path d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-1-5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zM4 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm0 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" /> </svg> },
            { title: "My referrals", path: ROUTE_MY_REFERRALS, svg: <svg xmlns="http://www.w3.org/2000/svg" width="25" height="22" fill="currentColor" className="bi bi-people me-3" viewBox="0 0 16 16"> <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z" /> </svg> },
            { title: "Payout", path: ROUTE_PAYOUT, svg: <svg xmlns="http://www.w3.org/2000/svg" width="25" height="22" fill="currentColor" className="money-withdrawal-icon me-3" viewBox="0 0 24 24"><path d="M22,2H2A1,1,0,0,0,1,3v8a1,1,0,0,0,1,1H5v9a1,1,0,0,0,1,1H18a1,1,0,0,0,1-1V12h3a1,1,0,0,0,1-1V3A1,1,0,0,0,22,2ZM7,20V18a2,2,0,0,1,2,2Zm10,0H15a2,2,0,0,1,2-2Zm0-4a4,4,0,0,0-4,4H11a4,4,0,0,0-4-4V8H17Zm4-6H19V7a1,1,0,0,0-1-1H6A1,1,0,0,0,5,7v3H3V4H21Zm-9,5a3,3,0,1,0-3-3A3,3,0,0,0,12,15Zm0-4a1,1,0,1,1-1,1A1,1,0,0,1,12,11Z" /></svg> },
            { title: "Payment history", path: ROUTE_PAYMENT_HISTORY, svg: <svg xmlns="http://www.w3.org/2000/svg" width="25" height="22" fill="currentColor" className="bi bi-clock-history me-3" viewBox="0 0 16 16"> <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z" /> <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z" /> <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z" /> </svg> },
        ], position: "TOP"
    },
    {
        title: "Portfolio", svg: <svg style={{ "color": "rgb(128, 131, 137)" }} xmlns="http://www.w3.org/2000/svg" width="25" height="22" fill="currentColor" className="bi bi-briefcase mb-1" viewBox="0 0 16 16"> <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z" fill="#808389" /> </svg>, menues: [
            { title: "Upload", path: ROUTE_UPLOAD, svg: <svg xmlns="http://www.w3.org/2000/svg" width="25" height="22" fill="currentColor" className="bi bi-upload me-3" viewBox="0 0 16 16"><path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"></path><path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z"></path></svg> },
            { title: "Proccess content", path: ROUTE_PROCESS_CONTENT, svg: <svg xmlns="http://www.w3.org/2000/svg" width="25" height="22" fill="currentColor" className="bi bi-bookmark me-3" viewBox="0 0 24 24"><path d="M21.5,1.5a1,1,0,0,0-1,1h0a5,5,0,1,0,.3,7.75,1,1,0,0,0-1.32-1.51,3,3,0,1,1,.25-4.25H18.5a1,1,0,0,0,0,2h3a1,1,0,0,0,1-1v-3A1,1,0,0,0,21.5,1.5Zm-3,12a1,1,0,0,0-1,1v.39L16,13.41a2.77,2.77,0,0,0-3.93,0l-.7.7L8.91,11.62a2.79,2.79,0,0,0-3.93,0L3.5,13.1V7.5a1,1,0,0,1,1-1h5a1,1,0,0,0,0-2h-5a3,3,0,0,0-3,3v12a3,3,0,0,0,3,3h12a3,3,0,0,0,3-3v-5A1,1,0,0,0,18.5,13.5Zm-14,7a1,1,0,0,1-1-1V15.93L6.4,13a.79.79,0,0,1,1.09,0l3.17,3.17,0,0L15,20.5Zm13-1a1,1,0,0,1-.18.53l-4.51-4.51.7-.7a.78.78,0,0,1,1.1,0l2.89,2.9Z" /></svg> },
            // { title: "Proccess content", path: ROUTE_UPLOADS, svg: <svg xmlns="http://www.w3.org/2000/svg" width="25" height="22" fill="currentColor" className="bi bi-bookmark me-3" viewBox="0 0 24 24"><path d="M21.5,1.5a1,1,0,0,0-1,1h0a5,5,0,1,0,.3,7.75,1,1,0,0,0-1.32-1.51,3,3,0,1,1,.25-4.25H18.5a1,1,0,0,0,0,2h3a1,1,0,0,0,1-1v-3A1,1,0,0,0,21.5,1.5Zm-3,12a1,1,0,0,0-1,1v.39L16,13.41a2.77,2.77,0,0,0-3.93,0l-.7.7L8.91,11.62a2.79,2.79,0,0,0-3.93,0L3.5,13.1V7.5a1,1,0,0,1,1-1h5a1,1,0,0,0,0-2h-5a3,3,0,0,0-3,3v12a3,3,0,0,0,3,3h12a3,3,0,0,0,3-3v-5A1,1,0,0,0,18.5,13.5Zm-14,7a1,1,0,0,1-1-1V15.93L6.4,13a.79.79,0,0,1,1.09,0l3.17,3.17,0,0L15,20.5Zm13-1a1,1,0,0,1-.18.53l-4.51-4.51.7-.7a.78.78,0,0,1,1.1,0l2.89,2.9Z" /></svg> },
            { title: "Submit content", path: ROUTE_SUBMIT_CONTENT, svg: <svg xmlns="http://www.w3.org/2000/svg" width="25" height="22" fill="currentColor" className="bi bi-cloud-arrow-up me-3" viewBox="0 0 16 16"> <path fillRule="evenodd" d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2z" /> <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" /> </svg> },
            { title: "Pending content", path: ROUTE_PENDING_CONTENT, svg: <svg xmlns="http://www.w3.org/2000/svg" width="25" height="22" fill="currentColor" className="bi bi-clock-history me-3" viewBox="0 0 16 16"> <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z" /> <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z" /> <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z" /> </svg> },
            { title: "Reviewed content", path: ROUTE_REVIEW_CONTENT, svg: <svg xmlns="http://www.w3.org/2000/svg" width="25" height="22" fill="currentColor" className="bi bi-chat-left me-3" viewBox="0 0 16 16"> <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" /> </svg> },
            { title: "Catalog manager", path: ROUTE_PORTFOLIO, svg: <svg xmlns="http://www.w3.org/2000/svg" width="25" height="22" fill="currentColor" className="bi bi-filter-square me-3" viewBox="0 0 16 16"> <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" /> <path d="M6 11.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" /> </svg> },
            // { title: "Manage releases", path: "/manage-releases", svg: <svg xmlns="http://www.w3.org/2000/svg" width="25" height="22" fill="currentColor" className="bi bi-pencil me-3" viewBox="0 0 16 16"> <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" /> </svg> },
            { title: "Keyword suggestions", path: ROUTE_SUBMIT_CONTENT_KEYWORD_TOOL, svg: <svg xmlns="http://www.w3.org/2000/svg" width="25" height="22" fill="currentColor" className="bi bi-search me-3" viewBox="0 0 16 16"> <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" /> </svg> },
        ], position: "TOP"
    },
    {
        title: "Insights", icon: "bi-activity", svg: <svg style={{ "color": "rgb(128, 131, 137)" }} xmlns="http://www.w3.org/2000/svg" width="25" height="22" fill="currentColor" className="bi bi-activity mb-1" viewBox="0 0 16 16"> <path fillRule="evenodd" d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2Z" fill="#808389" /> </svg>, menues: [
            { title: "Top performers", path: ROUTE_TOP_PERFORMERS, svg: <svg xmlns="http://www.w3.org/2000/svg" width="25" height="22" fill="currentColor" className="bi bi-graph-up-arrow me-3" viewBox="0 0 16 16"> <path fillRule="evenodd" d="M0 0h1v15h15v1H0V0Zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5Z" /> </svg> },
            { title: "Contributor blog", path: ROUTE_CONTRIBUTOR_BLOG, svg: <svg xmlns="http://www.w3.org/2000/svg" width="25" height="22" fill="currentColor" className="bi bi-bookmark me-3" viewBox="0 0 16 16"> <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5V2zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1H4z" /> </svg> },
            { title: "Customer Reference Photo", path: ROUTE_CUSTOMER_REF_PHOTOS, svg: <svg xmlns="http://www.w3.org/2000/svg" width="25" height="22" fill="currentColor" className="bi bi-bookmark me-3" viewBox="0 0 16 16"> <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5V2zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1H4z" /> </svg> }
        ], position: "TOP"
    },
    {
        title: "Account", svg: <svg xmlns="http://www.w3.org/2000/svg" width="25" height="22" fill="currentColor" className="bi bi-person mb-1" viewBox="0 0 16 16"> <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" /> </svg>, menues: [
            { title: "Account settings", path: ROUTE_ACCOUNT_SETTINGS, svg: <svg xmlns="http://www.w3.org/2000/svg" width="25" height="22" fill="currentColor" className="bi bi-nut me-3" viewBox="0 0 16 16"> <path d="m11.42 2 3.428 6-3.428 6H4.58L1.152 8 4.58 2h6.84zM4.58 1a1 1 0 0 0-.868.504l-3.428 6a1 1 0 0 0 0 .992l3.428 6A1 1 0 0 0 4.58 15h6.84a1 1 0 0 0 .868-.504l3.429-6a1 1 0 0 0 0-.992l-3.429-6A1 1 0 0 0 11.42 1H4.58z" /> <path d="M6.848 5.933a2.5 2.5 0 1 0 2.5 4.33 2.5 2.5 0 0 0-2.5-4.33zm-1.78 3.915a3.5 3.5 0 1 1 6.061-3.5 3.5 3.5 0 0 1-6.062 3.5z" /> </svg> },
            { title: "Public profile", path: ROUTE_PUBLIC_PROFILE, svg: <svg xmlns="http://www.w3.org/2000/svg" width="25" height="22" fill="currentColor" className="bi bi-person me-3" viewBox="0 0 16 16"> <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" /> </svg> },
            { title: "Notification Settings", path: ROUTE_NOTIFICATION_SETTINGS, svg: <svg xmlns="http://www.w3.org/2000/svg" width="25" height="22" fill="currentColor" className="bi bi-app-indicator me-3" viewBox="0 0 16 16"> <path d="M5.5 2A3.5 3.5 0 0 0 2 5.5v5A3.5 3.5 0 0 0 5.5 14h5a3.5 3.5 0 0 0 3.5-3.5V8a.5.5 0 0 1 1 0v2.5a4.5 4.5 0 0 1-4.5 4.5h-5A4.5 4.5 0 0 1 1 10.5v-5A4.5 4.5 0 0 1 5.5 1H8a.5.5 0 0 1 0 1H5.5z" /> <path d="M16 3a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" /> </svg> }
        ], position: "BOTTOM"
    },
    { title: "Help", svg: <svg style={{ "color": "rgb(128, 131, 137)" }} xmlns="http://www.w3.org/2000/svg" width="25" height="22" fill="currentColor" className="bi bi-question-circle mb-1" viewBox="0 0 16 16"> <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" fill="#808389" /> <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" fill="#808389" /> </svg>, path: ROUTE_HELP, menues: [], position: "BOTTOM" }
];
//sidebar section 
const Sidebar = () => {
    const { t } = useTranslation();
    const MENU_NOT_SELECTED = -1;
    const [selectedMenuIndex, setSelectedMenuIndex] = useState(MENU_NOT_SELECTED);
    const offcanvasRef = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (offcanvasRef.current && !offcanvasRef.current.contains(event.target as Node)) {
                setSelectedMenuIndex(MENU_NOT_SELECTED);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const SlidingbarLeft = () => {
        return (<div className={"offcanvas offcanvas-start animate__animateds animate__fadeInsLeft" + ((selectedMenuIndex != MENU_NOT_SELECTED) ? " show" : "")} ref={offcanvasRef} id="earningspanel" data-bs-scroll="true">
            <div className="offcanvas-header">
                <h3 className="offcanvas-title">{(selectedMenuIndex != MENU_NOT_SELECTED) ? t(subMenuesData[selectedMenuIndex].title) : "-"}</h3>
                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" onClick={() => setSelectedMenuIndex(MENU_NOT_SELECTED)}></button>
            </div>
            <div className="offcanvas-body">
                <ul>
                    {(selectedMenuIndex != MENU_NOT_SELECTED) && subMenuesData[selectedMenuIndex].menues.map(({ title, path, svg }: any, i:number) => {
                        return (path === ROUTE_CONTRIBUTOR_BLOG || path === ROUTE_THE_SHORT_LIST ? <li key={i}><a className="menu" href={path === ROUTE_CONTRIBUTOR_BLOG ? process.env.REACT_APP_BLOG : process.env.REACT_APP_THE_SHORT_LIST} target="_blank">{svg}{t(title)}</a></li> : <li><Link to={path} onClick={(event) => {
                                setSelectedMenuIndex(MENU_NOT_SELECTED);
                                let loggedInUser = AppSession.getSession();
                                if (loggedInUser.address === null || loggedInUser.address === "") {
                                    event.preventDefault();
                                    toast.error("Please Update All Account Details.", { position: toast.POSITION.TOP_RIGHT })
                                }
                            }} className="menu">{svg}{t(title)}</Link></li>)
                    })}
                </ul>
            </div>
        </div>)
    }

    return (<React.Fragment>
        <SlidingbarLeft />
        <div className="fixsidesub">
            <ul className="nav nav-pills navtop" id="menu">
                {subMenuesData.map((obj, i) => {
                    if (obj.position == "TOP") {
                        if (!obj.path && obj.menues) {
                            return (<li key={i}><a type="button" className={"nav-link " + ((selectedMenuIndex == i) ? "active" : "")} data-bs-toggle="offcanvas" data-bs-target="#earningspanel"
                                onClick={() => setSelectedMenuIndex(i)}> {obj.svg}
                                <span>{t(obj.title)}</span></a></li>)
                        }
                        if (obj.path) {
                            return (<li key={i}><Link to={obj.path} className={"nav-link " + ((selectedMenuIndex == i) ? "active" : "")}
                                onClick={(event) => {
                                    setSelectedMenuIndex(MENU_NOT_SELECTED);
                                    let loggedInUser = AppSession.getSession();
                                    if (loggedInUser.address === null || loggedInUser.address === "") {
                                        event.preventDefault();
                                        toast.error("Please Update All Account Details.", { position: toast.POSITION.TOP_RIGHT })
                                    }
                                }}> {obj.svg}
                                <span>{t(obj.title)}</span></Link></li>)
                        }
                    }
                })}
            </ul>
            <ul className="nav nav-pills navbottom">
                {subMenuesData.map((obj, i) => {
                    if (obj.position == "BOTTOM") {
                        if (!obj.path && obj.menues) {
                            return (<li key={i}><a type="button" className={"nav-link " + ((selectedMenuIndex == i) ? "active" : "")} data-bs-toggle="offcanvas" data-bs-target="#accountpanel"
                                onClick={() => setSelectedMenuIndex(i)}>
                                {obj.svg}<span>{t(obj.title)}</span></a></li>)
                        }
                        if (obj.path) {
                            return (<li key={i}><Link to={obj.path} className={"nav-link " + ((selectedMenuIndex == i) ? "active" : "")}
                                onClick={(event) => {
                                    setSelectedMenuIndex(MENU_NOT_SELECTED);
                                    let loggedInUser = AppSession.getSession();
                                    if (loggedInUser.address === null || loggedInUser.address === "") {
                                        event.preventDefault();
                                        toast.error("Please Update All Account Details.", { position: toast.POSITION.TOP_RIGHT })
                                    }
                                }}>{obj.svg}<span>{t(obj.title)}</span></Link></li>)
                        }
                    }
                })}</ul>
        </div>
    </React.Fragment>)
}
// Header 
const Header = (data: any) => {
    let loggedInUser = AppSession.getSession();
    useEffect(() => {
        if (window.location.pathname === ROUTE_SUBMIT_CONTENT_KEYWORD_TOOL) {
            localStorage.setItem("modalTime", '1');
        } else {
            localStorage.setItem("modalTime", '0');
        }
        if (window.location.pathname === ROUTE_HELP || window.location.pathname === ROUTE_SUBMIT_CONTENT || window.location.pathname === ROUTE_SUBMIT_CONTENT_KEYWORD_TOOL) {
            document.body.classList.add('help-content-modual');
        } else {
            document.body.classList.remove('help-content-modual');
        }
       
        setTimeout(() => {
            let loggedInUser = AppSession.getSession();
            const helpSection = localStorage.getItem("needHelpApply") as any;
            const referralPage = localStorage.getItem("referral") as any;
            if (loggedInUser.address === null || loggedInUser.address === "") {
                if (window.location.pathname !== ROUTE_ACCOUNT_SETTINGS) {

                    window.location.href = ROUTE_ACCOUNT_SETTINGS;
                }
            } else if (loggedInUser.address === null) {
                if (window.location.pathname !== ROUTE_ACCOUNT_SETTINGS) {
                    window.location.href = ROUTE_ACCOUNT_SETTINGS;
                }
            } else if (loggedInUser.is_mou_signed !== DEFAULT_ACTIVE_VALUE) {
                if (window.location.pathname !== ROUTE_MOU) {
                    window.location.href = ROUTE_MOU;
                }
            } else if (helpSection === 'y') {
                localStorage.setItem("needHelpApply", 'n');
                window.location.href = ROUTE_HELP;
            } else if (referralPage === 'y') {
                localStorage.setItem("referral", 'n');
                window.location.href = ROUTE_MY_REFERRALS;
            } else if (loggedInUser.is_mou_signed === DEFAULT_ACTIVE_VALUE) {
                if (window.location.pathname === ROUTE_MOU) {
                    // window.location.href = ROUTE_DASHBOARD;
                }
            }
        }, 1000)
    });
    const { t } = useTranslation();
    const MENU_NOT_SELECTED = -1;
    const [selectedMenuIndex, setSelectedMenuIndex] = useState(MENU_NOT_SELECTED);
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const [showSidebar, setShowSidebar] = useState(false);
    const toggleSidebar = () => {
        setShowSidebar(!showSidebar);
    }
    const logout = () => {
        doLogout();
    }
    const offCanRight = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (offCanRight.current && !offCanRight.current.contains(event.target as Node)) {
                setShowSidebar(showSidebar);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    let imgSrc = "";
    // right sidebar section 
    const SlidingbarRight = () => {
        return (<div className={"offcanvas offcanvas-end animate__animateds animate__fadeInsRight" + (showSidebar ? " show" : "")} tabIndex={-1} id="offcanvasRight" ref={offCanRight} aria-labelledby="offcanvasRightLabel" >
            <div className="offcanvas-header profile-log-head">
                <div className="profile-log-text d-flex align-items-center">
                    <div className="profile-log-img">
                        <Link to="" className="userimg"><img src={data.profileData.image ? DEFAULT_IMAGE_STAGE_CDN + "/" + data.profileData.image : (imgSrc.length <= 0 ? createImageFromInitials(500, loggedInUser.display_name ? loggedInUser.display_name : data.profileData.firstname + data.profileData.lastname, getRandomColor()) : imgSrc)} width="225" height="225" alt="" /> </Link>
                    </div>
                    <div>
                        <h3>{t("Hi")}, {data.profileData.firstname + " " + data.profileData.lastname}</h3>
                        <small>{t("Contributor ID")}: <span>{data.profileData.contributor_id}</span></small>
                    </div>
                </div>
                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => toggleSidebar()}></button>
            </div>
            <div className="offcanvas-body" >
                <div className="profile-log-card">
                    <h1>{t("Welcome to the new Cliqsotck")} <br />
                        {t("Contributor experience")}.</h1>
                    <p>{t("We are in the process of rebuilding our platform")} <br /> {t("for better performance and ease of use")}.</p>
                    <Link type="button" className="btn btn-outline-light me-2" to={ROUTE_FEEDBACK} onClick={() =>setShowSidebar(!showSidebar)}>{t("Leave feedback")}</Link>
                    <Link type="button" className="btn btn-outline-light" to={ROUTE_FEEDBACK} onClick={() =>setShowSidebar(!showSidebar)}>{t("Learn more")}</Link>
                </div>

                <div className="profile-logout-btn">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="22" fill="currentColor" className="bi bi-power" viewBox="0 0 16 16"> <path d="M7.5 1v7h1V1h-1z" fill="#828282"></path> <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" fill="#828282"></path> </svg>
                    <a type="button" className="btn btn-outline-none" onClick={() => {
                        setShowMobileMenu(false);
                        AppSession.logout();
                        window.location.reload();
                    }}>{t("Log out")}</a>
                </div>
            </div >
        </div >)
    }

    return (<React.Fragment>
        <SlidingbarRight />
        <header  id="elements1">
            <nav className="navbar">
                <div className="container-fluid headermain">
                    <a className="navbar-brand" href={process.env.REACT_APP_BASE_URL}><img src={logoContributor} alt="Cliqstock" /></a>
                    <button className="navbar-toggler d-lg-none" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                        aria-label="Toggle navigation"
                        onClick={() => setShowMobileMenu(!showMobileMenu)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5" />
                        </svg>
                    </button>

                    <div className={"collapse navbar-collapse animate__animateds animate__fadeInsDown" + (showMobileMenu ? " show" : "")} id="navbarSupportedContent">
                        <div className="muserbox">
                            <div className="muserbox-img"><img src={data.profileData.image ? DEFAULT_IMAGE_STAGE_CDN + "/" + data.profileData.image : (imgSrc.length <= 0 ? createImageFromInitials(500, loggedInUser.display_name ? loggedInUser.display_name : data.profileData.firstname + data.profileData.lastname, getRandomColor()) : imgSrc)} width="225" height="225" alt="" /></div>
                            <div className="muserbox-cnt">
                                <h3>Hi, {AppSession.getSession().firstname + " " + AppSession.getSession().lastname}</h3>
                                <p>Contributor ID: <span>{data.profileData.contributor_id}</span></p>
                            </div>
                        </div>
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            {subMenuesData.map((obj, i:number) => {
                                if (!obj.path && obj.menues) {
                                    return (<React.Fragment key={i}>
                                        {(i > 0 && obj.position != subMenuesData[i - 1].position) ? (<li className="nav-item" key={i}><hr /></li>) : <></>}
                                        <li className="nav-item dropdown" key={i}>
                                            <a type="button" className={"nav-link dropdown-toggle " + ((selectedMenuIndex == i) ? "active" : "")} data-bs-toggle="offcanvas" data-bs-target="#earningspanel" onClick={() => setSelectedMenuIndex(selectedMenuIndex == i ? MENU_NOT_SELECTED : i)}> {obj.svg}<span>{obj.title}</span></a>
                                            <ul className={"dropdown-menu animate__animated animate__animateds animate__fadeIns" + ((selectedMenuIndex == i) ? " show" : "")} aria-labelledby="navbarDropdown">
                                                {obj.menues.map((obj2: any, j: number) => {
                                                    return (<li key={j}><Link to={obj2.path} onClick={(event) => {
                                                        setShowMobileMenu(!showMobileMenu);
                                                        let loggedInUser = AppSession.getSession();
                                                        if (loggedInUser.address === null || loggedInUser.address === "") {
                                                            event.preventDefault();
                                                            toast.error("Please Update All Account Details.", { position: toast.POSITION.TOP_RIGHT })
                                                        }
                                                    }}>{obj2.svg}{t(obj2.title)}</Link></li>)
                                                })}
                                            </ul>
                                        </li>
                                    </React.Fragment>
                                    )
                                }
                                if (obj.path) {
                                    return (<li><Link to={obj.path} className={"nav-link " + ((selectedMenuIndex == i) ? "active" : "")}
                                        onClick={(event) => {
                                            setShowMobileMenu(!showMobileMenu)
                                            let loggedInUser = AppSession.getSession();
                                            if (loggedInUser.address === null || loggedInUser.address === "") {
                                                event.preventDefault();
                                                toast.error("Please Update All Account Details.", { position: toast.POSITION.TOP_RIGHT })
                                            }
                                        }}>{obj.svg}<span>{t(obj.title)}</span></Link></li>)
                                }
                            })}
                            <li className="nav-item">
                                <a type="button" className="nav-link"
                                    onClick={() => {
                                        setShowMobileMenu(false);
                                        AppSession.logout();
                                        window.location.reload();
                                    }}><svg xmlns="http://www.w3.org/2000/svg" width="25" height="22" fill="currentColor" className="bi bi-power me-3" viewBox="0 0 16 16"> <path d="M7.5 1v7h1V1h-1z" fill="#828282"></path> <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" fill="#828282"></path> </svg><span>{t("Log out")}</span></a></li>
                            <li>
                                <div className="offcanvas-body" >
                                    <div className="profile-log-card">
                                        <h1>{t("Welcome to the new Cliqsotck")} <br />
                                            {t("Contributor experience")}.</h1>
                                        <p>{t("We are in the process of rebuilding our platform")} <br /> {t("for better performance and ease of use")}.</p>
                                        <Link type="button" className="btn btn-outline-light me-2 text-light mt-2" to={ROUTE_FEEDBACK} onClick={() =>setShowMobileMenu(false)}>{t("Leave feedback")}</Link>
                                        <Link type="button" className="btn btn-outline-light text-light mt-2" to={ROUTE_FEEDBACK} onClick={() =>setShowMobileMenu(false)}>{t("Learn more")}</Link>
                                    </div>
                                </div>
                            </li>
                        </ul>

                    </div>
                    <div className="float-end topright d-none d-lg-block">
                        <span className="small me-2">{t("Unpaid Earnings")}: {formatDecimal(data.data.unpaid_amount ? data.data.unpaid_amount : 0)}</span>
                        <Link to={ROUTE_UPLOAD} className="btn btn-outline-primary addmediabtn"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="22" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16"> <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" /> </svg>{t('Add')}</Link>
                        <a type="button" className="userimg" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" onClick={() => toggleSidebar()}><img src={data.profileData.image ? DEFAULT_IMAGE_STAGE_CDN + "/" + data.profileData.image : (imgSrc.length <= 0 ? createImageFromInitials(500, loggedInUser.display_name ? loggedInUser.display_name : data.profileData.firstname + data.profileData.lastname, getRandomColor()) : imgSrc)} width="225" height="225" alt="" /></a>
                    </div>

                </div>
            </nav>
        </header>
    </React.Fragment>)
}
class AuthenticatedLayout extends React.Component<any, any> {
    state = { rows: [] as any, minuteCount: 0, earningsData: null as any, timerId: '' as any };
    private timerId: NodeJS.Timeout | null = null;
    getfooter = () => {
        axios.get(API_FOOTER)
            .then((response: any) => {
                this.setState({ rows: response.data['data'] })
                const serializedData = JSON.stringify(response.data['data']);
                localStorage.setItem("footerData", serializedData);
            }).catch((error: any) => {
                showError(error, false);
            });
    };

    expandItem = (rowIndex: number) => {
        const rows = [...this.state.rows];
        rows[rowIndex].expanded = !rows[rowIndex].expanded;
        this.setState({ rows });
    };
    componentDidMount(): void {
        this.props.getProfile();
        const footerData: any = localStorage.getItem('footerData');
        if (!footerData) {
            this.getfooter();
        } else {
            this.setState({ rows: JSON.parse(footerData) });
        }
        // Load minute count and earnings data from local storage
        const storedMinuteCount = parseInt(localStorage.getItem('minuteCount') || '0', 10);
        const storedEarningsData = JSON.parse(localStorage.getItem('earningsData') || 'null');

        // Calculate the difference between the current time and the last API call time
        const currentTime: any = new Date();
        const lastApiCallTime: any = new Date(localStorage.getItem('lastApiCallTime') || 0);
        const timeDifference = Math.floor((currentTime - lastApiCallTime) / (1000 * 60));

        if (timeDifference >= 15) {
            // If 15 minutes or more have passed, make the API call
            this.fetchEarningsAmount();
        } else {
            // Use the stored data
            this.setState({
                showLoader: false,
                minuteCount: storedMinuteCount,
                earningsData: storedEarningsData,
            });
        }

        this.timerId = setInterval(this.fetchEarningsAmount, 15 * 60 * 1000);
      
    }

    componentWillUnmount() {
        // Clear the timer when the component is unmounted
        if (this.timerId !== null) {
            clearInterval(this.timerId);
        }
    }
    fetchEarningsAmount = () => {
        // Perform API call
        this.setState({ showLoader: true });
        axios.get(API_GET_EARNINGS_AMOUNT)
            .then((response) => {
                // Update state with API response
                this.setState({ showLoader: false, earningsData: response.data.data });
                // Update minute count
                this.setState((prevState: any) => ({ minuteCount: prevState.minuteCount + 15 }));
                // Store data in local storage
                localStorage.setItem('minuteCount', this.state.minuteCount.toString());
                localStorage.setItem('earningsData', JSON.stringify(response.data.data));
                localStorage.setItem('lastApiCallTime', new Date().toString());
            })
            .catch((error) => {
                console.error(error);
                this.setState({ showLoader: false });
            });
    };

    render() {
        const url = window.location.pathname;
        const { profileData, t } = this.props;
        const lang = localStorage.getItem("i18nextLng");
        return (
            <React.Fragment>
                {this.state.earningsData && profileData ? <Header data={this.state.earningsData} profileData={profileData} /> : <></>}
                <div className="page-content" id="stickybar">
                    <Sidebar/>
                    <div className={url === "/submit-content" || url === '/' + lang + '/submit-content' ? "site-content justify-content-start" : "site-content"}>
                        {this.props.children ? this.props.children: <div></div>}
                        <footer className="footer-area" style={{position:'relative', bottom:0, zIndex:1}}>
                            <div className="footer-widget">
                                <div className="container plmanage-lg">
                                    <div className="row footer-widget-wrapper">
                                        {this.state.rows &&
                                            this.state.rows.map((row: any, key: number) => (
                                                <div key={key} className="col-lg-2 col-md-4  col-sm-4">
                                                    <div className="footer-widget-box list">
                                                        <h4 className="footer-widget-title">{t(row.title)}</h4>
                                                        <ul className="footer-list">
                                                            {row.data && row.data.length !== 0 ? (
                                                                row.data.map((item: any, itemIndex: number) => (
                                                                    <li key={itemIndex}>
                                                                        <a href={item.link} target="_blank" className="text-hover-link">
                                                                            {t(item.title)}
                                                                        </a>
                                                                    </li>
                                                                ))
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                    <div className="row d-block d-lg-none footer-widget-m">
                                        {this.state.rows && this.state.rows.map((row: any, i: number) => {
                                            return (<div className="col-12 mb-3" key={i}>
                                                <button type="button" className="collapsed" data-bs-toggle="collapse" data-bs-target={`#Content${i}`} aria-expanded={row.expanded ? true : false} onClick={() => this.expandItem(i)} >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down float-end" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708" />
                                                    </svg>
                                                    {t(row.title)}
                                                </button>
                                                <div id={`Content${i}`} className={`collapse animate__animateds animate__fadeIns ${row.expanded ? 'show' : ''}`} key={i}>
                                                    <ul>
                                                        {row.data && row.data.length !== 0 ? (
                                                            row.data.map((item: any, itemIndex: number) => (
                                                                <li key={itemIndex}>
                                                                    <a href={item.link} target="_blank" className="text-hover-link">
                                                                        {t(item.title)}
                                                                    </a>
                                                                </li>
                                                            ))
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </ul>
                                                </div>
                                            </div>)
                                        })}
                                    </div>
                                    <div className="container-fluid">
                                        <div className="reg-btn col-md-12 text-center">
                                            <LanguageDropdown />
                                        </div>
                                    </div>
                                </div>
                                <div className="copyright">
                                    <div className="container plmanage-lg">
                                        <div className="row">
                                            <div className="col-md-6 col-lg-6 col-sm-6">
                                                <p className="copyright-text">
                                                    &copy; {t("Copyright")} <span id="date">{new Date().getFullYear()}</span>{" "}
                                                    <a href="https://www.cliqstock.com" target="_blank" className="text-hover-link"> CLIQSTOCK </a>
                                                    {t("All Rights Reserved.")}
                                                </p>
                                            </div>
                                            <div className="col-md-6 col-lg-6 col-sm-6">
                                                <ul className="footer-social">
                                                    <li>
                                                        <a href="https://www.facebook.com/cliqstock" target="_blank">{t('facebook')}</a>
                                                    </li>
                                                    <li>
                                                        <a href="https://twitter.com/cliqstock" target="_blank">{t('twitter')}</a>
                                                    </li>
                                                    <li>
                                                        <a href="https://www.instagram.com/cliqstock/" target="_blank">{t('instagram')}</a>
                                                    </li>
                                                    <li>
                                                        <a href="https://www.linkedin.com/company/cliqstock/" target="_blank">{t('linkedin')}</a>
                                                    </li>
                                                    <li>
                                                        <a href="https://www.youtube.com/channel/UCHZYbGZBkDVTTfUqV6_7YFQ" target="_blank">{t('youtube')}</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </footer>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}


const mapStateToProps = (state: any) => {
    return {
        profileData: state.profileData,
        earningData: state.earningData,
        footerData: state.footerData,
    };
};
const mapDispatchToProps = {
    getProfile,
    getfooter,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AuthenticatedLayout));