import AppSession from "../security/AppSession";

export const formatDecimal = (num: number,isNotCurrency?:boolean) => {
    let c=AppSession.getCurrencyObject();
    if(!num){
        return isNotCurrency?(c["currency_symbol"]+0.00):(c["currency_symbol"]+"0.00");
    }
    if(Number.isNaN(num)){
        return (c["currency_symbol"]+0.00);
    }
    // return Math.round((num * 100) / 100).toFixed(2)
    return (c["currency_symbol"]+parseFloat(num+"").toFixed(c.currency == "USD" ? 4 : 2));
}

export const formatNumber=(number:any)=>{
    if(!number){
        return 0;
    }
    if(Number.isNaN(number)){
        return 0;
    }
    return number;

}