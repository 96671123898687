import { useNavigate } from 'react-router-dom';
import gifSuccess from '../assets/images/success.gif';
import { ROUTE_LOGIN } from '../network/urls';
export default function SuccessPage(props:any){
    ///TODO: Pending work
    const navigate=useNavigate();
    setTimeout(function(){
        navigate(ROUTE_LOGIN);
    },2000);
    return(<div className="d-flex justify-content-center align-items-center">
        <div className="d-flex flex-column">
            <img src={gifSuccess} alt="Success"/>
            <h3>Email sent successfully</h3>
        </div>
    </div>)
}