import React, { useEffect, useState } from "react"
import { get, map } from "lodash"
import { withTranslation } from "react-i18next"

//i18n
import i18n from "./i18n"
import { Dropdown } from "react-bootstrap"
import DropdownToggle from "react-bootstrap/esm/DropdownToggle"
import DropdownMenu from "react-bootstrap/esm/DropdownMenu"
import DropdownItem from "react-bootstrap/esm/DropdownItem"
import axios from "../../network/axios"
import { API_GET_LANGUAGE } from "../../network/apis"
import { showError, showErrors } from "../../common/error-handler"
import { Translate } from "aws-sdk"
import {
  useLocation,
  useNavigate,
  useParams
} from "react-router-dom";
const LanguageDropdown = (props: any) => {
  const [languages, setLanguages] = useState([] as any);
  const [selectedLang, setSelectedLang] = useState("")
  useEffect(() => {
    const languageData: any = localStorage.getItem('languageData');
    if (!languageData) {
      getLanguage();
    } else {
      let langs = {} as any;
      JSON.parse(languageData).forEach((row: any) => {
        if (row && row["status"]) {
          langs[row["code"]] = row;
        }
      });
      setLanguages(langs);
    }
    const currentLanguage = localStorage.getItem("I18N_LANGUAGE") as any;
    setSelectedLang(currentLanguage)
  }, []);
  // get languages
  const getLanguage = () => {
    axios.get(API_GET_LANGUAGE)
      .then((response: any) => {
        let langs = {} as any;
        response.data['data'].forEach((row: any) => {
          if (row && row["status"]) {
            langs[row["code"]] = row;
          }
        });
        setLanguages(langs);
        const serializedData = JSON.stringify(response.data['data']);
        localStorage.setItem("languageData", serializedData);
      })
      .catch((error: any) => {
        showError(error, false);
      });
  }

  const changeLanguageAction = (lang: string) => {
    const currentLanguage = localStorage.getItem("I18N_LANGUAGE") as string;
    const newPath = window.location.pathname.replace(`/${currentLanguage}`, '');

    i18n.changeLanguage(lang);
    localStorage.setItem("I18N_LANGUAGE", lang);
    setSelectedLang(lang);

    let newUrl;
    if (lang !== "en") {
      newUrl = `/${lang}${newPath}`;
    } else {
      newUrl = newPath;
    }

    window.location.href = newUrl;
  };
  // Split the languages into three columns
  const columns = [[], [], []] as any;
  Object.keys(languages).forEach((key, index) => {
    columns[index % 3].push(key);
  });

  return (
    <>
      <Dropdown className="d-md-block ms-2 mb-2 dd-lang dropup">
        <DropdownToggle className={props.className ? props.className : "btn header-item waves-effect"}>
          {" "}{get(languages, `${selectedLang}.name`)}
        </DropdownToggle>
        <DropdownMenu className="language-switch dropdown-menu-end test" style={{ right: 0, width: 200 }}>
          <div className="row">
            {Object.keys(languages).map((key: any,i:number) => (
              <li key={i}>
                <DropdownItem key={key} onClick={() => changeLanguageAction(key)} className={`notify-item ps-3 fs-6 ${selectedLang === key ? "active" : ""}`} >
                  <span className="align-middle">
                    {languages[key]?.name}
                  </span>
                </DropdownItem>
              </li>
            ))}
          </div>
        </DropdownMenu>
      </Dropdown>
    </>
  )
}

export default withTranslation()(LanguageDropdown)
