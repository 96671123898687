import i18n from "i18next"
import detector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"
import translationCS from "../../locales/cs/translation.json"
import translationDA from "../../locales/da/translation.json"
import translationDE from "../../locales/de/translation.json"
import translationEN from "../../locales/en/translation.json"
import translationFI from "../../locales/fi/translation.json"
import translationFR from "../../locales/fr/translation.json"
import translationGU from "../../locales/gu/translation.json"
import translationHI from "../../locales/hi/translation.json"
import translationHU from "../../locales/hu/translation.json"
import translationIT from "../../locales/it/translation.json"
import translationJA from "../../locales/ja/translation.json"
import translationKO from "../../locales/ko/translation.json"
import translationNB from "../../locales/nb/translation.json"
import translationNL from "../../locales/nl/translation.json"
import translationPL from "../../locales/pl/translation.json"
import translationPT from "../../locales/pt/translation.json"
import translationRU from "../../locales/ru/translation.json"
import translationSV from "../../locales/sv/translation.json"
import translationTH from "../../locales/th/translation.json"
import translationTR from "../../locales/tr/translation.json"
import translationZH from "../../locales/zh/translation.json"
import translationZhHant from "../../locales/zh-Hant/translation.json"

// the translations
const resources = {
  cs: {
    translation: translationCS,
  },
  da: {
    translation: translationDA,
  },
  de: {
    translation: translationDE,
  },
  en: {
    translation: translationEN,
  },
  fi: {
    translation: translationFI,
  },
  fr: {
    translation: translationFR,
  },
  gu: {
    translation: translationGU,
  },
  hi: {
    translation: translationHI,
  },
  hu: {
    translation: translationHU,
  },
  it: {
    translation: translationIT,
  },
  ja: {
    translation: translationJA,
  },
  ko: {
    translation: translationKO,
  },
  nb: {
    translation: translationNB,
  },
  nl: {
    translation: translationNL,
  },
  pl: {
    translation: translationPL,
  },
  pt: {
    translation: translationPT,
  },
  ru: {
    translation: translationRU,
  },
  sv: {
    translation: translationSV,
  },
  th: {
    translation: translationTH,
  },
  tr: {
    translation: translationTR,
  },
  zh: {
    translation: translationZH,
  },
  'zh-Hant': {
    translation: translationZhHant,
  },
};

const language = localStorage.getItem("I18N_LANGUAGE")
if (!language) {
  localStorage.setItem("I18N_LANGUAGE", "en")
}

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem("I18N_LANGUAGE") || "en",
    fallbackLng: "en", // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
