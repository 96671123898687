import axios from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { showError, showErrors } from "../common/error-handler";
import { API_LOGOUT } from "../network/apis";
import { ROUTE_HOME } from "../network/urls";
import AppSession from "../security/AppSession";

export default function Logout(props: any) {
    const navigate = useNavigate();
    useEffect(() => {
        window.location.href = ROUTE_HOME;
        AppSession.logout();
        axios.get(API_LOGOUT).then(() => {
            navigate(ROUTE_HOME);
        }).catch((error: any) => {
            showError(error, false);
            navigate(ROUTE_HOME);
        });
    }, []);
    return (<div className="w-100 h-100 d-flex justify-content-center align-items-center">
        <h3>Please wait...</h3>
    </div>)
}