import axios from "axios";
import { toast } from "react-toastify";
import { showError } from "../common/error-handler";
import { DEFAULT_SUCCESS_MESSAGE } from "../common/messages";
import AppSession from "../security/AppSession";
import { API_LOGOUT } from "./apis";
import { ROUTE_HOME } from "./urls";

// create api request 
const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 60000,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
});

instance.interceptors.request.use((request: any) => {
    const currentLanguage = localStorage.getItem("I18N_LANGUAGE") as any;
    request.headers["Accept-Language"] = currentLanguage ? currentLanguage : "en";

    if (AppSession.isLoggedIn()) {
        request.headers["Authorization"] = "Bearer " + AppSession.getToken();
    }
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log("Request:", request);
    }
    return request;
},
    (error: any) => {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            console.log("Request Error:", error);
        }
        return Promise.reject(error);
    }
);

// logout user 
export function doLogout() {
    instance.get(API_LOGOUT).then((response) => {
        toast.success(response.data ? response.data.message : DEFAULT_SUCCESS_MESSAGE, {
            position: toast.POSITION.TOP_RIGHT
        });
        AppSession.logout();
        window.location.href = ROUTE_HOME;
    }).catch((error) => {
        showError(error, false);
        // window.location.href = ROUTE_HOME;
    });
    // window.location.href = ROUTE_HOME;
}

export default instance;