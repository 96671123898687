export const NEW_AND_CONFIRM_PASSWORD_NOT_SAME = "New and confirm password should be same";
export const ENTER_REQUIRED_FIELDS = "Please enter required fields";
export const DEFAULT_SUCCESS_MESSAGE = "Successful";
export const DEFAULT_ERROR_MESSAGE = "Unauthenticated";
export const REQUIRED_FIRSTNAME = "Enter firstname";
export const REQUIRED_LASTNAME = "Enter lastname";
export const REQUIRED_EMAIL = "Enter email id";
export const REQUIRED_PASSWORD = "Enter password";
export const REQUIRED_OTP = "Enter OTP";
export const REQUIRED_CODE = "Enter Code";
export const REQUIRED_TITLE = "Enter Title";