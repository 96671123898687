import React from "react"
import SuccessPage from '../pages/SuccessPage';
import { ROUTE_ACCOUNT_SETTINGS, ROUTE_CONTACT, ROUTE_CUSTOMER_REF_PHOTOS, ROUTE_DASHBOARD, ROUTE_DOWNLOAD_INVOICE, ROUTE_EARNING_SUMMARY, ROUTE_FEEDBACK, ROUTE_FORGOT_PASSWORD, ROUTE_HELP, ROUTE_HOME, ROUTE_INVOICES, ROUTE_LOGIN, ROUTE_LOGOUT, ROUTE_MOU, ROUTE_MY_REFERRALS, ROUTE_NOTIFICATION_SETTINGS, ROUTE_PAYMENT_HISTORY, ROUTE_PAYOUT, ROUTE_PORTFOLIO, ROUTE_PROCESS_CONTENT, ROUTE_PUBLIC_PROFILE, ROUTE_REGISTER, ROUTE_SOCIAL_LOGIN_CALLBACK, ROUTE_SUCCESS, ROUTE_TAX_CENTER, ROUTE_TOP_PERFORMERS, ROUTE_VERIFY, ROUTE_WITHDRAW_INVOICE, ROUTE_SOCIAL_AUTO_POST_LOGIN_CALLBACK, ROUTE_UPLOAD, ROUTE_SUBMIT_CONTENT, ROUTE_PENDING_CONTENT, ROUTE_REVIEW_CONTENT, ROUTE_WAVERIFY, ROUTE_SUBMIT_CONTENT_KEYWORD_TOOL, ROUTE_EMAIL_VERIFY, ROUTE_RESET_PASSWORD_LINK,ROUTE_VERIFY_USER } from "../network/urls";
import Home from "../pages/Home";
import Logout from "../pages/Logout";
// all routes create 
const authenticatedRoutes = [
    { path: ROUTE_ACCOUNT_SETTINGS, component: React.lazy(() => import('../pages/AccountSettings')), lazy: true },
    { path: ROUTE_PUBLIC_PROFILE, component: React.lazy(() => import('../pages/PublicProfile')), lazy: true },
    { path: ROUTE_MOU, component: React.lazy(() => import('../pages/Mou')), lazy: true },
    { path: ROUTE_DASHBOARD, component: React.lazy(() => import('../pages/Dashboard')), lazy: true },
    { path: ROUTE_CONTACT, component: React.lazy(() => import('../pages/Contact')), lazy: true },
    { path: ROUTE_MY_REFERRALS, component: React.lazy(() => import('../pages/MyReferrals')), lazy: true },
    { path: ROUTE_HELP, component: React.lazy(() => import('../pages/Help')), lazy: true },
    { path: ROUTE_FEEDBACK, component: React.lazy(() => import('../pages/Feedback')), lazy: true },
    { path: ROUTE_CUSTOMER_REF_PHOTOS, component: React.lazy(() => import('../pages/CustomerRefPhotos')), lazy: true },
    { path: ROUTE_EARNING_SUMMARY, component: React.lazy(() => import('../pages/EarningSummary')), lazy: true },
    { path: ROUTE_INVOICES, component: React.lazy(() => import('../pages/Invoices')), lazy: true },
    { path: ROUTE_NOTIFICATION_SETTINGS, component: React.lazy(() => import('../pages/NotificationSettings')), lazy: true },
    { path: ROUTE_PAYMENT_HISTORY, component: React.lazy(() => import('../pages/PaymentHistory')), lazy: true },
    { path: ROUTE_PAYOUT, component: React.lazy(() => import('../pages/Payout')), lazy: true },
    { path: ROUTE_PORTFOLIO, component: React.lazy(() => import('../pages/Portfolio')), lazy: true },
    { path: ROUTE_UPLOAD, component: React.lazy(() => import('../pages/Upload')), lazy: true },
    { path: ROUTE_PROCESS_CONTENT, component: React.lazy(() => import('../pages/ProcessContent')), lazy: true },
    { path: ROUTE_PENDING_CONTENT, component: React.lazy(() => import('../pages/PendingContent')), lazy: true },
    { path: ROUTE_REVIEW_CONTENT, component: React.lazy(() => import('../pages/ReviewContent')), lazy: true },
    { path: ROUTE_SUBMIT_CONTENT, component: React.lazy(() => import('../pages/SubmitContent')), lazy: true },
    { path: ROUTE_SUBMIT_CONTENT_KEYWORD_TOOL, component: React.lazy(() => import('../pages/SubmitContent')), lazy: true },
    { path: ROUTE_SUBMIT_CONTENT_KEYWORD_TOOL, component: React.lazy(() => import('../pages/EditContent')), lazy: true },
    { path: ROUTE_TAX_CENTER, component: React.lazy(() => import('../pages/TaxCenter')), lazy: true },
    { path: ROUTE_TOP_PERFORMERS, component: React.lazy(() => import('../pages/TopPerformers')), lazy: true },
    { path: ROUTE_SOCIAL_AUTO_POST_LOGIN_CALLBACK, component: React.lazy(() => import('../pages/SocialAutoPostCallback')), lazy: true },
    { path: ROUTE_SOCIAL_AUTO_POST_LOGIN_CALLBACK, component: React.lazy(() => import('../pages/SocialAutoPostCallback')), lazy: true },
]

const pathName = window.location.pathname;

const newPathName = pathName.substring(ROUTE_REGISTER.length);
const path = pathName.replace(newPathName, '');
const lang = localStorage.getItem("i18nextLng");

if (pathName.startsWith("/register")) {
    let afterRegister = "";
    if (lang === "en") {
        // afterRegister = pathName.substring(ROUTE_REGISTER.length);
        afterRegister = newPathName.startsWith('/') ? newPathName.substring(1) : newPathName;
    } else {
        afterRegister = pathName.substring('/register/'.length);
        // afterRegister = newPathName.startsWith('/') ? newPathName.substring(1) : newPathName;
    }
    localStorage.setItem("referralBy", afterRegister);
}

const guestRoutes = [
    { path: ROUTE_HOME, component: Home, lazy: false },
    { path: ROUTE_LOGIN, component: React.lazy(() => import('../pages/Login')), lazy: true },
    { path: ROUTE_SOCIAL_LOGIN_CALLBACK, component: React.lazy(() => import('../pages/LoginSocialCallback')), lazy: true },
    { path: ROUTE_REGISTER, component: React.lazy(() => import('../pages/Register')), lazy: true },
    { path: path === ROUTE_REGISTER ? pathName : ROUTE_REGISTER, component: React.lazy(() => import('../pages/Register')), lazy: true },
    { path: ROUTE_FORGOT_PASSWORD, component: React.lazy(() => import('../pages/ForgotPassword')), lazy: true },
    { path: ROUTE_VERIFY + "/:verificationToken", component: React.lazy(() => import('../pages/EmailVerificationPage')), lazy: true },
    { path: ROUTE_VERIFY, component: React.lazy(() => import('../pages/EmailVerificationPage')), lazy: true },
    { path: ROUTE_SUCCESS, component: SuccessPage, lazy: false },
]

const commmoRoutes = [
    { path: ROUTE_WAVERIFY, component: React.lazy(() => import('../pages/WhatsappVerification')), lazy: true },
    { path: ROUTE_EMAIL_VERIFY, component: React.lazy(() => import('../pages/VerificationPage')), lazy: true },
    { path: ROUTE_RESET_PASSWORD_LINK + "/:verificationToken", component: React.lazy(() => import('../pages/ResetPassword')), lazy: true },
    { path: ROUTE_LOGOUT, component: Logout, lazy: false },
    { path: ROUTE_WITHDRAW_INVOICE, component: React.lazy(() => import('../pages/WithdrawInvoice')), lazy: true },
    { path: ROUTE_DOWNLOAD_INVOICE, component: React.lazy(() => import('../pages/WithdrawInvoice')), lazy: true },
    { path: ROUTE_VERIFY_USER, component: React.lazy(() => import('../pages/VerifyUser')), lazy: true },
]

export { authenticatedRoutes, guestRoutes, commmoRoutes }

