import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { authenticatedRoutes, commmoRoutes, guestRoutes } from './routes/AllRoutes';
import GeustLayout from './layouts/GuestLayout';
import AuthenticatedLayout from './layouts/AuthenticatedLayout';
import AppSession from './security/AppSession';
import { ROUTE_DASHBOARD, ROUTE_HOME, ROUTE_LOGIN, ROUTE_SUBMIT_CONTENT_KEYWORD_TOOL } from './network/urls';
import CommonLayout from './layouts/CommonLayout';
import TopProgressbar from './TopProgressbar';
import { connect } from 'react-redux';
import { getProfile } from './pages/store/apiaction';
import HomePage from './widgets/GmailLoader';
import { showError, showErrors } from './common/error-handler';
import axios from 'axios';
import { CountryCodes } from './common/CountryCodes';


class App extends React.Component<any, any> {
  state = { showLoader: false, loggedIn: AppSession.isLoggedIn(), loggedInUser: undefined };
  render() {
    var latlong = { "latitude": null, "longitude": null, time: 0 };
    const latlongData: any = localStorage.getItem('latlong');
    const indexModal: any = localStorage.getItem('indexModal');
    const modalTime = localStorage.getItem('modalTime');
    
    if (!indexModal) {
      localStorage.setItem('indexModal', "");
    }
    const data = JSON.parse(latlongData);
    if (!data) {
      const serializedData = JSON.stringify(latlong);
      localStorage.setItem("latlong", serializedData);
    }
    const helpSection = localStorage.getItem("needHelpApply") as any;
    if (helpSection === 'y') {
      if (window.location.pathname === '/' && !AppSession.isLoggedIn()) {
        localStorage.setItem("needHelpApply", 'n');
      } else if (window.location.pathname === '/register' && !AppSession.isLoggedIn()) {
        localStorage.setItem("needHelpApply", 'n');
      } else if (window.location.pathname === '/forgotpassword' && !AppSession.isLoggedIn()) {
        localStorage.setItem("needHelpApply", 'n');
      }
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          var latlong = { "latitude": latitude, "longitude": longitude, time: 1 };
          const serializedData = JSON.stringify(latlong);
          const latlongData: any = localStorage.getItem('latlong');
          const data = JSON.parse(latlongData);
          AppSession.updateSessionData(latlong);
          localStorage.setItem("latlong", serializedData);
          if (data.time === 0) {
            axios.post(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`).then((response: any) => {
              const result = response.data.results[0];
              const addressComponents = result.address_components;
              let data: any = [];
              data["plus_code"] = addressComponents.find((comp: any) => comp.types.includes('plus_code'))?.long_name || '';
              data["city"] = addressComponents.find((comp: any) => comp.types.includes('locality'))?.long_name || '';
              data["state"] = addressComponents.find((comp: any) => comp.types.includes('administrative_area_level_1'))?.long_name || '';
              data["country"] = addressComponents.find((comp: any) => comp.types.includes('country'))?.long_name || '';
              data["zip"] = addressComponents.find((comp: any) => comp.types.includes('postal_code'))?.long_name || '';
              data["country_code"] = addressComponents.find((comp: any) => comp.types.includes('country'))?.short_name || '';
              const address = result.formatted_address;
              data["address"] = address.replace(data.plus_code + ",", '').replace(data.city + ",", '').replace(data.state, '').replace(data.zip + ",", '').replace(data.zip, '').replace(data.country, '');
              const foundCountry = CountryCodes.find(country => country.code === data.country_code);
              let dialCode: any = foundCountry?.dial_code;
              let Address = { address: data.address ? data.address : "", city: data.city ? data.city : "", country: data.country ? data.country : "", state: data.state ? data.state : "", zip: data.zip ? data.zip : "", country_calling_code: dialCode ? dialCode : "", country_code: data.country_code ? data.country_code : "", latitude: latitude, longitude: longitude };
              const serializedData = JSON.stringify(Address);
              localStorage.setItem("locationData", serializedData);
              localStorage.setItem("CurruntlocationData", serializedData);
              this.setState({ showLoader: false });
            }).catch((error) => {
              this.setState({ showLoader: false });
              showError(error, false);
              let data: any = [];
              data["plus_code"] = '';
              data["city"] = '';
              data["state"] = '';
              data["country"] = '';
              data["country_code"] = '';
              data["zip"] = '';
              data["address"] = '';
              let Address = { address: data.address, city: data.city, country: data.country, state: data.state, zip: data.zip, country_calling_code: "", country_code: data.country_code, latitude: latitude, longitude: longitude };
              const serializedData = JSON.stringify(Address);
              localStorage.setItem("locationData", serializedData);
              localStorage.setItem("CurruntlocationData", serializedData);
            });
          }
        },
        (error) => {
          var latlong = { "latitude": null, "longitude": null, time: 0 };
          const serializedData = JSON.stringify(latlong);
          localStorage.setItem("latlong", serializedData);
        }
      );
    }
    if (this.state.showLoader) {
      return (
        <HomePage />
      )
    }
    return (
      <>
        <BrowserRouter>
          <Routes>
            <Route>
              {commmoRoutes.map(({ path: Url, component: Component, lazy: Lazy }, idx) => (
                <Route
                  {...this.props}
                  path={Url}
                  element={<CommonLayout>
                    {Lazy ? <Suspense fallback={<LazyLoader />}><Component {...this.props} /></Suspense> : <Component {...this.props} />}
                  </CommonLayout>}
                  key={"cr-" + idx}
                />
              ))}
            </Route>

            {!this.state.loggedIn ?
              guestRoutes.map(({ path: Url, component: Component, lazy: Lazy }, idx) => (
                <Route>
                  <Route
                    {...this.props}
                    path={Url}
                    element={
                      Url == ROUTE_HOME ? <Component {...this.props} /> : <GeustLayout>
                        {Lazy ? <Suspense fallback={<LazyLoader />}>
                          <Component {...this.props} /></Suspense> : <Component {...this.props} />}
                      </GeustLayout>}
                    key={"gr-" + idx}
                  />
                </Route>
              ))
              :
              authenticatedRoutes.map((o, idx) => {
                const Url = o.path as any;
                const Lazy = o.lazy as any;
                const Component = o.component as any;
                return (
                  <Route key={idx}>
                    <Route
                      {...this.props}
                      path={Url}
                      element={<AuthenticatedLayout>
                        {Lazy ? <Suspense fallback={<LazyLoader />}>
                          <Component {...this.props} /></Suspense> : <Component {...this.props} />}
                      </AuthenticatedLayout>}
                      key={"ar-" + idx}
                    />
                  </Route>
                );
              })
            }
            <Route path="*" element={<Navigate to={!AppSession.isLoggedIn() ? ROUTE_LOGIN : ROUTE_DASHBOARD} />} />
          </Routes>
        </BrowserRouter>
      </>);
  }
}

function LazyLoader(props: any) {
  return (
    <div className="w-100 h-100 d-flex justify-content-center align-items-center"></div>
  )
}

const getprofileData = (state: any) => ({
  data: state.data,
});
export default connect(getprofileData, { getProfile })(TopProgressbar(App));
