import { toast } from "react-toastify";
import { DEFAULT_ERROR_MESSAGE } from "./messages";
import { API_SAVE_ERROR_LOG } from "../network/apis";
import axios, { doLogout } from "../network/axios";
import { useTranslation } from "react-i18next";
import AppSession from "../security/AppSession";
import { ROUTE_LOGIN } from "../network/urls";
const logout = () => {
    setTimeout(() => {
        window.location.href = ROUTE_LOGIN;
        AppSession.logout();
    }, 1000)
}
export const getError = (error: any, showSimpleMessage: any) => {
    let errorMsg = undefined;
    if (showSimpleMessage) {
        try { errorMsg = error.response.data.message; } catch (err) { }
    }
    if (!errorMsg) {
        errorMsg = error.message;
        if (error.response && error.response.data) {
            if (error.response.data.data) {
                /*INFO: Sometimes message returing in form of array and sometime in string */
                let err = error.response.data.data[Object.keys(error.response.data.data)[0]];
                errorMsg = Array.isArray(err) ? err[0] : err;
            }
            else {
                errorMsg = error.response.data.message;
            }
        }
    }
    return errorMsg ? errorMsg : DEFAULT_ERROR_MESSAGE;
}
export const showErrors = (error: any, showSimpleMessage: any, translate: any) => {
    const errorMsg = getError(error, showSimpleMessage);
    if (errorMsg === "Unauthenticated") {
        logout();
    }
    toast.error(translate(errorMsg), { position: toast.POSITION.TOP_RIGHT });
    logToServer(errorMsg, error);
}

export const showError = (error: any, showSimpleMessage: any) => {
    const errorMsg = getError(error, showSimpleMessage);
    if (errorMsg === "Unauthenticated") {
        logout();
    }
    toast.error(errorMsg, { position: toast.POSITION.TOP_RIGHT });
    logToServer(errorMsg, error);
}
const logToServer = (errorMsg: any, error: any) => {
    axios.post(API_SAVE_ERROR_LOG, { url: window.location.href, request_data: errorMsg, error_code: error.code, error_detail: errorMsg })
        .then((response: any) => {
            console.log("Log succcessfully on server");
        }).catch((error) => {
            console.log("Unable to log on server");
        });
}

