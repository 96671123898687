import { API_FOOTER, API_GET_COUNTRIES, API_GET_EARNINGS_AMOUNT, API_GET_LANGUAGE, API_GET_PROFILE } from "../../network/apis";
import axios from "../../network/axios";
import { showError } from "../../common/error-handler";
import AppSession from "../../security/AppSession";

export const fetchDataSuccess = (data: any) => ({
    type: 'GET_PROFILE_DATA',
    payload: data,
});

export const EarningData = (data: any) => ({
    type: 'GET_EARNING_DATA',
    payload: data,
});
export const LanguageData = (data: any) => ({
    type: 'GET_LANGUAGE_DATA',
    payload: data,
});
export const FooterData = (data: any) => ({
    type: 'GET_FOOTER_DATA',
    payload: data,
});
export const CountryData = (data: any) => ({
    type: 'GET_COUNTRY_DATA',
    payload: data,
});

// get user profile 
export const getProfile = (data: any) => {
    return (dispatch: any, getState: any) => {
        const { profileData } = getState();
        if (data === 1) {
            axios.get(API_GET_PROFILE)
                .then((response: any) => {
                    const Address = response.data.data.address;
                    const serializedData = JSON.stringify(Address);
                    if (Address !== null || Address !== "") {
                        localStorage.setItem("locationData", serializedData);
                    }
                    const loggedInUser = response.data['data'];
                    const storedData = localStorage.getItem('locationData');
                    const existingData = storedData ? JSON.parse(storedData) : {};
                    const updatedData = { ...existingData, ...loggedInUser };
                    localStorage.setItem('cs-session-data', JSON.stringify(updatedData));
                    AppSession.updateSessionData(loggedInUser);
                    dispatch(fetchDataSuccess(loggedInUser));
                })
                .catch((error: any) => {
                    showError(error, false);
                });
        }
        if (profileData.length !== 0) {
            return;
        } else {
            axios.get(API_GET_PROFILE)
                .then((response: any) => {
                    const Address = response.data.data.address;
                    const serializedData = JSON.stringify(Address);
                    if (Address !== null || Address) {
                        localStorage.setItem("locationData", serializedData);
                    }
                    const loggedInUser = response.data['data'];
                    AppSession.updateSessionData(loggedInUser);
                    dispatch(fetchDataSuccess(loggedInUser));
                })
                .catch((error: any) => {
                    showError(error, false);
                });
        }
    };
};
// get earning amount 
export const getEarningsAmount = () => {
    return (dispatch: any, getState: any) => {
        const { earningData } = getState();
        if (earningData.length !== 0) {
            return;
        } else {
            axios.get(API_GET_EARNINGS_AMOUNT)
                .then((response: any) => {
                    dispatch(EarningData(response.data['data']));
                })
                .catch((error: any) => {
                    showError(error, false);
                });
        }
    };
};

// get language 
export const getLanguage = () => {
    return (dispatch: any, getState: any) => {
        const { languageData } = getState();
        if (languageData.length !== 0) {
            return;
        } else {
            axios.get(API_GET_LANGUAGE)
                .then((response: any) => {
                    dispatch(LanguageData(response.data['data']));
                })
                .catch((error: any) => {
                    showError(error, false);
                });
        }
    };
};
// get footer 
export const getfooter = () => {
    return (dispatch: any, getState: any) => {
        const { footerData } = getState();
        if (footerData.length !== 0) {
            return;
        } else {
            axios.get(API_FOOTER)
                .then((response: any) => {
                    dispatch(FooterData(response.data['data']));
                    const serializedData = JSON.stringify(response.data['data']);
                    localStorage.setItem("footerData", serializedData);
                }).catch((error: any) => {
                    showError(error, false);
                });
        }
    };
};

// get country 
export const getCountry = () => {
    return (dispatch: any, getState: any) => {
        const { countryData } = getState();
        if (countryData.length !== 0) {
            return;
        } else {
            axios.post(API_GET_COUNTRIES).then((response: any) => {
                dispatch(CountryData(response.data['data']));
            }).catch((error: any) => {
                showError(error, false);
            });
        }
    };
};

