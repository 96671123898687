let lang: any = localStorage.getItem('i18nextLng');
if (lang === null) {
    lang = "/en";
} else {
    lang = "/" + lang;
}

// all page routes 
export const ROUTE_HOME = `${lang !== "/en" ? lang : "/"}`;
export const ROUTE_LOGIN = `${lang !== "/en" ? lang : ""}/login`;
export const ROUTE_REGISTER = `${lang !== "/en" ? lang : ""}/register`;
export const ROUTE_FORGOT_PASSWORD = `${lang !== "/en" ? lang : ""}/forgotpassword`;
export const ROUTE_VERIFY_CODE = `/verifycode`;
export const ROUTE_RESET_PASSWORD = `${lang !== "/en" ? lang : ""}/resetpassword`;
export const ROUTE_VERIFY = `/verify`;
export const ROUTE_LOGOUT = `${lang !== "/en" ? lang : ""}/logout`;
export const ROUTE_SUCCESS = `${lang !== "/en" ? lang : ""}/success`;
export const ROUTE_SOCIAL_LOGIN_CALLBACK = "/socialLoginCallback/:type";
export const ROUTE_SOCIAL_AUTO_POST_LOGIN_CALLBACK = "/socialAutoPostCallback/:type";

export const ROUTE_ACCOUNT_SETTINGS = `${lang !== "/en" ? lang : ""}/account-settings`;
export const ROUTE_PUBLIC_PROFILE = `${lang !== "/en" ? lang : ""}/public-profile`;
export const ROUTE_MOU = `${lang !== "/en" ? lang : ""}/mou`;
export const ROUTE_DASHBOARD = `${lang !== "/en" ? lang : ""}/dashboard`;
export const ROUTE_CONTACT = `/contact`;
export const ROUTE_MY_REFERRALS = `${lang !== "/en" ? lang : ""}/my-referrals`;
export const ROUTE_HELP = `/help`;
export const ROUTE_FEEDBACK = `${lang !== "/en" ? lang : ""}/feedback`;
export const ROUTE_CUSTOMER_REF_PHOTOS = `${lang !== "/en" ? lang : ""}/customer-ref-photos`;
export const ROUTE_EARNING_SUMMARY = `${lang !== "/en" ? lang : ""}/earning-summary`;
export const ROUTE_INVOICES = `${lang !== "/en" ? lang : ""}/invoices`;
export const ROUTE_NOTIFICATION_SETTINGS = `${lang !== "/en" ? lang : ""}/notification-settings`;
export const ROUTE_PAYMENT_HISTORY = `${lang !== "/en" ? lang : ""}/payment-history`;
export const ROUTE_PAYOUT = `${lang !== "/en" ? lang : ""}/payout`;
export const ROUTE_TAX_CENTER = `${lang !== "/en" ? lang : ""}/tax-center`;
// export const ROUTE_CONTENT_STATUS = `/content/:status`;
export const ROUTE_PORTFOLIO = `${lang !== "/en" ? lang : ""}/portfolio`;
export const ROUTE_PROCESS_CONTENT = `${lang !== "/en" ? lang : ""}/process-content`;
export const ROUTE_PENDING_CONTENT = `${lang !== "/en" ? lang : ""}/pending-content`;
export const ROUTE_REVIEW_CONTENT = `${lang !== "/en" ? lang : ""}/review-content`;
export const ROUTE_TOP_PERFORMERS = `${lang !== "/en" ? lang : ""}/top-performers`;
export const ROUTE_CONTRIBUTOR_BLOG = `${lang !== "/en" ? lang : ""}/contributor-blog`;
export const ROUTE_WITHDRAW_INVOICE = `${lang !== "/en" ? lang : ""}/withdraw-invoice`;
export const ROUTE_DOWNLOAD_INVOICE = `${lang !== "/en" ? lang : ""}/download-invoice`;
export const ROUTE_UPLOAD = `${lang !== "/en" ? lang : ""}/upload`;
export const ROUTE_SUBMIT_CONTENT = `${lang !== "/en" ? lang : ""}/submit-content`;
export const ROUTE_THE_SHORT_LIST = `${lang !== "/en" ? lang : ""}/the_short_list"`;
export const ROUTE_WAVERIFY = `/whatsappverify`;
export const ROUTE_SUBMIT_CONTENT_KEYWORD_TOOL = `${lang !== "/en" ? lang : ""}/submit-content/keyword-tool`;
export const ROUTE_EMAIL_VERIFY = `/email-verify`;
export const ROUTE_RESET_PASSWORD_LINK = `${lang !== "/en" ? lang : ""}/reset-password`;
export const ROUTE_VERIFY_USER = `${lang !== "/en" ? lang : ""}/verify-user`;
